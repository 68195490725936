import React from "react";
import { AddSalesGoal } from "./AddSalesGoal";
import BootstrapTable from "react-bootstrap-table-next";
import { Button ,Form} from "react-bootstrap";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast } from "react-toastify";
import { IGetTaskTypeGoalData } from "../../../Common/Contracts/IGetTaskTypeGoalData";
import { ISalesActivityTypeMaster } from "../../../Common/Contracts/ISalesActivityTypeMaster";
import { Utils } from "../../../Common/Utilis";

export class Sales extends React.Component<any, any> {
  private adGoalRef: any = React.createRef();

  private salesGoalColumns = [
    {
      dataField: "typeName",
      text: "Task Type Goals",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div
          onClick={() =>
            (this.props?.isEditSalesInfo || this.props?.isNewUserAdded) ? this.editSalesGoal(row) : {}
          }
        >
          <span className="document-userName">{cell}</span>
        </div>
      ),
    },
    {
      dataField: "dayNo",
      text: "Day",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div
          onClick={() =>
            (this.props?.isEditSalesInfo || this.props?.isNewUserAdded) ? this.editSalesGoal(row) : {}
          }
        >
          <span className="document-dayNo">{cell}</span>
        </div>
      ),
    },

    {
      dataField: "weekNo",
      text: "Week",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div
          onClick={() =>
            (this.props?.isEditSalesInfo || this.props?.isNewUserAdded) ? this.editSalesGoal(row) : {}
          }
        >
          <span className="document-weekNo">{cell}</span>
        </div>
      ),
    },
    // {
    //   dataField: "",
    //   text: "",
    //   formatter: (cell: any, row: any, rowIndex: any) => (
    //     <Dropdown
    //       onClick={(e) => {
    //         e.stopPropagation();
    //       }}
    //       className="more-action"
    //       alignRight
    //     >
    //       <Dropdown.Toggle
    //         className="btn-outline-primary btn btn-primary btn-ghost more"
    //         id="dropdown-more"
    //       >
    //         <svg
    //           width="20"
    //           height="20"
    //           viewBox="0 0 20 20"
    //           fill="none"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
    //           <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
    //           <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
    //         </svg>
    //       </Dropdown.Toggle>
    //       <Dropdown.Menu>
    //         <Dropdown.Item
    //           key={row?.status}
    //           onClick={() => this.deleteUserGoal(row)}
    //           eventKey={"status"}
    //         >
    //           Delete
    //         </Dropdown.Item>
    //       </Dropdown.Menu>
    //     </Dropdown>
    //   ),
    // },
  ];

  state: any = {
    salesGoals: [],
    isSalesAgent: this.props?.userGeneralInfo?.isSalesAgent > 0 ? true : false,
    showBanner: false,
    isSalesLoaded: false,
    showAddSalesModal: false,
    salesRow: {},
    salesTaskTypesMain: [],
    isDocTypesLoaded: [],
  };

  componentDidMount() {
    this.getTaskTypeGoalData();
    this.getSalesActivityTypeMaster();
  }

  rowClasses = (row: any, rowIndex: any) => {
    if (this.state.isExpand === true && row.action === "Expended") {
      return "row-Expended";
    } else if (row.approveButtonSpinner) {
      return "p-none";
    } else if (this.state.isExpand === true) {
      return "row-Collapsed";
    } else {
      return "row-Action";
    }
  };

  handleSalesTabCount = () => {
    this.props?.handleSalesTabCount(
      (this.state.showBanner && this.state.isSalesAgent) ? 1: 0
    );
  };

  getTaskTypeGoalData = (isReload = false) => {
    this.setState({ isSalesLoaded: false });
    UserManagement.GetTaskTypeGoalData(this.props?.userName)
      .then((data: IGetTaskTypeGoalData[] | null) => {
        if ((data as any)?.length > 0) {
          this.setState({ showBanner: false }, () =>
            this.handleSalesTabCount()
          );
        } else {
          this.setState({ showBanner: true }, () => this.handleSalesTabCount());
        }
        this.setState({ salesGoals: data || [] }, () =>
          this.setState({ isSalesLoaded: true })
        );
      })
      .catch(() => this.setState({ isSalesLoaded: true }));
    if (isReload) {
      this.props?.getUserGeneralInfo(isReload);
    }
  };

  onIsSalesAgentChange = (event) => {
    this.setState({ isSalesAgent: event.target.checked }, () => {
      this.updateSalesToggle();
      this.handleSalesTabCount();
    });
  };

  deleteUserGoal = (row) => {
    UserManagement.DeleteUserGoal(this.props?.userName, row?.uniqueNo)
      .then((response) => {
        if (response?.result?.success) {
          toast.success("Sales Goal deleted successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "salesGoals",
          });
          this.getTaskTypeGoalData(true);
        } else {
          Utils.toastError(response?.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "salesGoals",
          });
        }
      })
      .catch((error) => {
        Utils.toastError(`Server Error, ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "salesGoals",
        });
      });
  };
  updateSalesToggle = () => {
    UserManagement.UpdateToggle(
      this.props?.userName,
      this.state.isSalesAgent ? 1 : 0,
      this.props?.userGeneralInfo?.allowPTO || 0,
      this.props?.userGeneralInfo?.allowACATracking || 0,
      this.props?.userGeneralInfo?.allowPerformance || 0,
      this.props?.userGeneralInfo?.allowDeposit || 0
    ).then((result) => {
      this.props?.getUserGeneralInfo(true);
      toast.success(result.result?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "salesGoals",
      });
    });
  };
  componentWillUnmount=()=> {
    // setTimeout(()=> {
    //   toast.dismiss();
  
    // },1000)
  }

  getSalesActivityTypeMaster = () => {
    this.setState({ salesTaskTypesMain: [], isDocTypesLoaded: true });
    UserManagement.GetSalesActivityTypeMaster()
      .then((data: ISalesActivityTypeMaster[] | null) => {
        this.setState({ salesTaskTypesMain: data || [] });
      })
      .finally(() => this.setState({ isDocTypesLoaded: true }));
  };

  isAdditionTaskTypeRemain =()=>{
    const _salesTaskTypes = this.state?.salesTaskTypesMain.filter(
      (item) =>
        !this.state?.salesGoals.map((x) => x?.uniqueNo).includes(item?.uniqueno)
    );

    if(_salesTaskTypes?.length ===0 )
    return false

    return true
  }

  addSalesGoal = () => {
    const _salesTaskTypes = this.state?.salesTaskTypesMain.filter(
      (item) =>
        !this.state?.salesGoals.map((x) => x?.uniqueNo).includes(item?.uniqueno)
    );
    if (_salesTaskTypes?.length === 0) {
      Utils.toastError("You don't have additional task types to add", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "salesGoals",
      });
      return;
    }

    this.setState({
      showAddSalesModal: true,
      salesRow: {},
    });
  };

  editSalesGoal = (row) => {
    debugger
    this.setState(
      {
        salesRow: row,
      },
      () => {
        this.setState({
          showAddSalesModal: true,
        });
      }
    );
  };

  hideSalesGoal = (isReloaRequired = false) => {
    if (isReloaRequired) {
      this.getTaskTypeGoalData(true);
    }
    this.setState({
      showAddSalesModal: false,
    });
  };

  render() {
    const {
      salesGoals,
      isSalesAgent,
      showBanner,
      isSalesLoaded,
      showAddSalesModal,
      salesRow,
      isDocTypesLoaded,
      salesTaskTypesMain,
    } = this.state;

    return (
      <>
        <div className="sales-tab">
        
          <div className="body-section">
            <div className="heading">Sales Settings</div>
            <div className="form-section d-flex flex-column mb-3">
              <Form.Group
                controlId="date-selection"
                className="d-flex flex-row"
              >
                <Form.Label>Sales Agent</Form.Label>
                <Form.Check
                  type="switch"
                  id="ChkIsSalesAgent"
                  label=""
                  name="ChkSalesAgent"
                  checked={isSalesAgent}
                  disabled={!(this.props?.isEditSalesInfo || this.props?.isNewUserAdded)}
                  onChange={(event: any) => {
                    this.onIsSalesAgentChange(event);
                  }}
                />
              </Form.Group>
            </div>
            {showBanner && isSalesAgent && (
              <div>
                <span className="notification-banners orange-bg">
                A goal must be added below.
                </span>
              </div>
            )}
          </div>
          {isSalesAgent && (
            <>
              <div className="separator-line"></div>
              <div className="body-section">
                <div className="heading">Goals</div>
                <div className="add-sales-wrapper">
                  {isSalesLoaded &&
                    isDocTypesLoaded &&
                    (this.props?.isEditSalesInfo || this.props?.isNewUserAdded) && (
                      <>
                      
                       {this.isAdditionTaskTypeRemain() && <Button
                          className="btn-ghost"
                          disabled={!(this.props?.isEditSalesInfo || this.props?.isNewUserAdded)}
                          onClick={(e: any) => this.addSalesGoal()}
                        >
                          Add Goal
                        </Button>}
                        {showAddSalesModal && (
                          <AddSalesGoal
                            userName={this.props?.userName}
                            salesGoals={salesGoals}
                            salesTaskTypesMain={salesTaskTypesMain}
                            salesRow={salesRow}
                            showAddSalesModal={showAddSalesModal}
                            hideSalesGoal={this.hideSalesGoal}
                          />
                        )}
                      </>
                    )}
                </div>
                <div className="add-sales-table">
                  <BootstrapTable
                    keyField="if_Record"
                    data={salesGoals}
                    columns={this.salesGoalColumns}
                    //expandRow={expandRow}
                    rowClasses={this.rowClasses}
                    hover
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
