import { User } from "../Services/User";
import { Utils } from "../../Common/Utilis";
import moment from "moment";
export class FreshDeskChat {


  static IntegrateFreshDeskChat = (username) => {
    let restoreID = null;
    let storage = JSON.parse(localStorage.getItem("storage") ||"");
    restoreID = storage === null ? 0 : storage.restoreID;
    window.fcWidget.init({
      token: "a53d8c25-5450-4932-83c6-e16382713985",
      host: "https://wchat.freshchat.com",
      // config: {
      //   headerProperty: {
      //     fontName: 'Poppins',
      //     fontUrl: 'https://fonts.googleapis.com/css2?family=Poppins&display=swap'         
      //   }
      // },
      externalId: username,
      restoreId: restoreID ? restoreID : null,
    });



    window.fcWidget.user.get(function (resp) {

      var status = resp && resp.status;
      // let storage = JSON.parse(localStorage.getItem("storage"));
      let firstName = storage === null ? 0 : storage.firstName;
      let lastName = storage === null ? 0 : storage.lastName;
      let email = storage === null ? 0 : storage.email;
      let tenantId = storage === null ? 0 : storage.tenantID;
      let hotelCodes = 'None' // TODO: This is not held in storage so it needs to be pulled from the database

      if (status !== 200) {
        window.fcWidget.user.setProperties({
          firstName: firstName, // user's first name
          lastName: lastName, // user's last name
          email: email, // user's email address
          eid: tenantId,  // Custom user property 
          hid: hotelCodes, // Custom user property
        });

        window.fcWidget.user.create(function (resp) {
          var status = resp.status,
            data = resp.data;
          if (status === 200) {
            if (data.restoreId) {
              let requestObject = {};
              requestObject.restoreId = data.restoreId;
              User.UpdateRestoreID(requestObject);
            }
          }
        });
      } else {
        window.fcWidget.user.update({
          firstName: firstName, // user's first name
          lastName: lastName, // user's last name
          email: email, // user's email address
          meta: {
            eid: tenantId, // Custom user property
            hid: hotelCodes, // Custom user property
          }
        });
      }
    });
  };

  static hideFreshDesk = () => {
    // window.fcWidget.init({
    //   "host": "https://wchat.freshchat.com",
    //   "token": "a53d8c25-5450-4932-83c6-e16382713985"
    // });

    // window.fcWidget.destroy();
    //window.fcWidget.hide();
    window.fcWidget.user.clear();
    window.fcWidget.destroy();
  }


  static openFreshDesk = () => {
    window.fcWidget.user.clear();
    window.fcWidget.open();
  }

  static showFreshDesk = () => {
    window.fcWidget.init({
      "host": "https://wchat.freshchat.com",
      "token": "a53d8c25-5450-4932-83c6-e16382713985"
    });

  }

  static redirectToFreshDeskURL = (url)=> {
  
    User.IsFreshDeskTokenExpired().then(data=> {
    const userdetail = JSON.parse(localStorage.getItem('storage') ||"");	
    Utils.setCookie("userName",userdetail.userName ,1);
    Utils.setCookie("token", localStorage.getItem('token') ,1);
    Utils.setCookie("expirationTimeForFreshDesk",  localStorage.getItem("expirationTimeForFreshDesk"));
    // const expirationTime =    Utils.getCookie("expirationTimeForFreshDesk") ||  localStorage.getItem("expirationTimeForFreshDesk") || data?.result  || "";
    // const duration = moment.duration(
    //   moment(expirationTime)?.diff(moment())
    // );
    // const seconds = duration._data.minutes * 60 + duration._data.seconds;
     Utils.setCookie("lastFreshdeskUrl",url,1)
     const isFreshDeskTokenExpired =data?.result;
  
    if( (!isFreshDeskTokenExpired) || !JSON.parse(localStorage.getItem("storage") ||"")?.freshdeskLoginUrl){	
   window.open(url,'_blank');
   User.UpdateLastClickedFreshDeskUrl("").then((result)=> {
    console.log(result);
        });    
    }	else{	
      window.open(JSON.parse(localStorage.getItem("storage")).freshdeskLoginUrl);
      User.UpdateLastClickedFreshDeskUrl(url).then((result)=> {
        console.log(result);
            });
    }
  });
}




}
