import React from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Row,
  Spinner,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import BootstrapTable from "react-bootstrap-table-next";
import { confirmAlert } from "react-confirm-alert";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import ReactTooltip from "react-tooltip";
import { UserManagement } from "../../../Common/Services/UserManagement";
import _ from "lodash";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { ILaborSettings } from "../../../Common/Contracts/ILaborSettings";
import { IPTOUserDetails } from "../../../Common/Contracts/IPTOUserDetails";
import { Utils } from "../../../Common/Utilis";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { ILaborPTOAdjBalance } from "../../../Common/Contracts/ILaborPTOAdjBalance";
import { AddPTOAdjustment } from "./AddPTOAdjustment";
import { AddPTOType } from "./AddPTOType";
import { AddPerformanceReview } from "./AddPerformanceReview";
import Calendar from "react-calendar";
import { ISavePTOUserDetailRequest } from "../../../Common/Contracts/ISavePTOUserDetailRequest";
import Joi from "joi-browser";
import { AddSpecialRate } from "./AddSpecialRate";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { HotelWideSettings } from "./HotelWideSettings";
import { IHotelWideSettingProps } from "../../../Common/Contracts/IHotelWideSettingProps";
import { ACACodeEdit } from "./ACACodeEdit";
import { ACAFileEditUpload } from "./ACAFileEditUpload";
import { AddPerformanceReviewApproval } from "./AddPerformanceReviewApproval";
import { TempHidSelectorCopy } from "../../../WebTimeClock/TempHidSelectorCopy";
import { ConfirmationModal } from "../../../Common/Components/ConfirmationModal";
import { resolve, reject } from "q";
import { IHotelWisePayRollRequest } from "../../../Common/Contracts/IHotelWisePayRoll";
import { VendorSearchUM } from "../common-components/VendorSearchUM";
import { InfoTooltipRed } from "../../../Common/Components/InfoTooltipRed";
import { PayrollAllowances } from "./PayrollAllowances";
import { IPayrollAllowancesProps } from "../../../Common/Contracts/IPayrollAllowancesProps";

export class Labor extends React.Component<any, any> {
  paymentTypes = [
    {
      label: "Paid Directly",
      value: 1,
    },
    {
      label: "Paid by Vendor/Agency",
      value: 2,
    },
  ];

  validations: any = [{}];
  countTimer: any;
  private optionsValidation = Joi.object().keys({
    startingBalance: Joi.string().required(),
    hid: Joi.number(),
    letterCode: Joi.string(),
  });
  private itemsArray = Joi.array()
    .items(
      Joi.object({
        startingBalance: Joi.string().required(),
      })
    )
    .min(1)
    .required();

  private laborSettingFormSchema = Joi.object({
    employeeType: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select employee type" };
        });
      }),
    dOB: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Date of Birth" };
        });
      }),
    averageHoursWorked: Joi.number().error((errors) => {
      return errors.map((error) => {
        return { message: "Input value should be number only" };
      });
    }),
    ssn: Joi.string()
      .required()
      .min(11)
      .regex(/^(?!(000|9|666))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/i, "ssn")
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "any.empty":
              return { message: "Please enter SSN" };
            case "string.min":
              return {
                message:
                  "Incorrect SSN format. SSN should not start with 9 format should include nine digits, for example 123-45-7689",
              };
            case "string.regex.name":
              return {
                message:
                  "Incorrect SSN format. SSN should not start with 9 format should include nine digits, for example 123-45-7689",
              };
          }
        });

        return [_errors[0]];
      }),
    vendorId: Joi.number()
      .min(1)
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Vendor/Agency" };
        });
      }),
    contractId: Joi.string().optional().allow(""),
    paidByVendor: Joi.number()
      .min(1)
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Payment" };
        });
      }),

    allowContactorOverTime: Joi.boolean().optional().allow(false),
  });

  private laborSettingFormSchemaContractor = Joi.object({
    employeeType: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select employee type" };
        });
      }),
    dOB: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Date of Birth" };
        });
      }),
    averageHoursWorked: Joi.number().error((errors) => {
      return errors.map((error) => {
        return { message: "Input value should be number only" };
      });
    }),
    ssn: Joi.string()
      .allow("")
      .optional()
      .min(11)
      .regex(/^(?!(000))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/i, "ssn")
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "string.min":
              return {
                message:
                  "Incorrect SSN format. SSN should include nine digits, for example 123-45-7689",
              };
            case "string.regex.name":
              return {
                message:
                  "Incorrect SSN format. SSN should should include nine digits, for example 123-45-7689",
              };
          }
        });

        return [_errors[0]];
      }),
    vendorId: Joi.number()
      .min(1)
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Vendor/Agency" };
        });
      }),
    contractId: Joi.string().optional().allow(""),
    paidByVendor: Joi.number()
      .min(1)
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Payment" };
        });
      }),

    allowContactorOverTime: Joi.boolean().optional().allow(false),
  });

  constructor(props: any) {
    super(props);
    this.state = {
      laborTimeOffData: [],
      isPTOUserListLoaded: false,
      laborTimeOffSubData: [],
      laborACATracking: [],
      showACAUpload: false,
      showACACodeEdit: false,
      tableExpended: props.ptoTableExpended || [],
      acaTableRow: {},
      calenderShow: false,
      isLaborSettingNotChanged: false,
      isFormValid: false,
      inputPosition: 4,
      isExpand: false,
      isHotelWideExpand: false,
      isAllowanceExpand: false,
      acaActions: ["Edit", "Upload"],
      showSalariedHoursEntryPanel: false,
      showHideDOBControl: false,
      employeeTypes: [],
      ptoRow: {},
      hotelWideRow: {},
      allowanceRow: {},
      ptoList: [],
      spacialRates: [],
      isDataSubmitting: false,
      doesPolicyDeactivationRequired: false,
      showHideExcludeFromPayroll: false,
      hotelWideTableData: [],
      allowanceTableData: [],
      showHotelWiseAddEditModal: false,
      showAllowanceAddEditModal: false,
      showSpecialRateAddEditModal: false,
      hotelWisePayrollRowEdit: {},
      allowancePayrollRowEdit: {},
      specialRateRowEdit: {},
      hotelWideTableExpended: props.hotelWideTableExpended || [],
      allowanceTableExpanded: [],
      laborSettingsErrors: {},
      ispayrollIntegrated: false,
      payrollIntegratedAtLeastOneHid: false,
      hasViewSensitiveDataPermission:
        this.props?.hasViewSensitiveDataPermission,
      prevLaborSettings: {
        employeeType: props?.userGeneralInfo?.employeeType || "",
        dOB: props?.userGeneralInfo?.dob || "",
        averageHoursWorked: +props?.userGeneralInfo.avergaeHoursWorked
          ? props?.userGeneralInfo.avergaeHoursWorked
          : 0.0,
        ssn: props?.userGeneralInfo?.ssn || "",
        vendorId: props?.userGeneralInfo?.vendorId || 0,
        contractId: props?.userGeneralInfo?.contractId || "",
        paidByVendor: props?.userGeneralInfo?.paidByVendor || "",
        allowContactorOverTime:
          props?.userGeneralInfo?.allowContactorOverTime === 1 ? true : false,
      },
      laborSettings: {
        employeeType: props?.userGeneralInfo?.employeeType || "",
        dOB: props?.userGeneralInfo?.dob || "",
        averageHoursWorked: +props?.userGeneralInfo.avergaeHoursWorked
          ? props?.userGeneralInfo.avergaeHoursWorked
          : 0.0,
        ssn: props?.userGeneralInfo?.ssn || "",
        vendorId: props?.userGeneralInfo?.vendorId || 0,
        contractId: props?.userGeneralInfo?.contractId || "",
        paidByVendor: props?.userGeneralInfo?.paidByVendor || "",
        allowContactorOverTime:
          props?.userGeneralInfo?.allowContactorOverTime === 1 ? true : false,
      },

      hotelWisePayrollBulkData: [],
      performanceReviews: [],

      addPerformanceReview: false,
      isHotelWisePayrollLoaded: false,
      isAllowancePayrollLoaded: false,
      isSpecialRateLoaded: false,
      isAllowPTO: +this.props?.userGeneralInfo?.allowPTO > 0 ? true : false,
      isAllowACATracking:
        +this.props?.userGeneralInfo?.allowACATracking > 0 ? true : false,
      isAllowPerformance:
        +this.props?.userGeneralInfo?.allowPerformance === 0 ? false : true,
      mappedPayRollHIDs: [],
      positionList: [],
      addPerformanceReviewApproval: false,
      performanceReviewComments: [],
      showPTOTypeModal: false,
      ptoTypeEditRow: {},
      isPTOTypeEdit: false,
      performanceTableExpended: [],
      performanceSectionTableExpended: [],
      isPerformanceReviewSectionExpand: false,
      duplicateSSNTableData: [],
      showDuplicateSSNModal: false,
      ptoNames: [],
      // showSSNValue:false
    };
  }

  componentDidMount() {
    //this.GetPayrollIntegrationHidsByTenant();
    const userhids = this.props.userGeneralInfo?.hids?.map((x) => x.hotelID);
    const IspayrollIntegration = this.allHidsExist(
      userhids,
      this.props.payrollIntegrationHids
    );
    const payrollIntegratedAtLeastOneHid = this.isUserHidInAll(
      userhids,
      this.props.payrollIntegrationHids
    );
    this.setState({
      ispayrollIntegrated: IspayrollIntegration,
      payrollIntegratedAtLeastOneHid: payrollIntegratedAtLeastOneHid,
    });
    this.loadDeaultData();
    this.checkFormModified();

    if(this.props.ptoTableExpended?.length > 0){
      this.getPTOAdjustedDetails(this.props.ptoTableExpended[0]);
    }
  }
  allHidsExist(userHids, allHids) {
    for (let i = 0; i < userHids?.length; i++) {
      if (!allHids?.includes(userHids[i])) {
        return false;
      }
    }
    return true;
  }

  isUserHidInAll(userHids, allHids) {
    for (let i = 0; i < userHids.length; i++) {
      if ((allHids || [])?.includes(userHids[i])) {
        return true;
      }
    }
    return false;
  }
  //  GetPayrollIntegrationHidsByTenant = () => {
  //   UserManagement.GetPayrollIntegrationHidsByTenant().then((data: any[]) => {
  //      const hids= this.props.userGeneralInfo?.hids?.map(x=>x.hotelID)
  //      const IspayrollIntegration=this.allHidsExist(hids,data);
  //     this.setState({ispayrollIntegrated :IspayrollIntegration,payrollIntegrationHids:data});
  //   }).catch(()=>{
  //     this.setState({ispayrollIntegrated :false,payrollIntegrationHids:[]});
  //   });
  // };
  handleShowDuplicateSSNModal = () => {
    this.setState({ showDuplicateSSNModal: true });
  };

  hideDuplicateSSNModal = () => {
    const laborSettingsErrors = _.cloneDeep(this.state.laborSettingsErrors);
    laborSettingsErrors.ssn =
      "This SSN is already associated with another user";
    this.setState({ laborSettingsErrors, showDuplicateSSNModal: false }, () =>
      this.calculateLaborTabCount()
    );
  };
  componentDidUpdate = (prevProps) => {
    if (this.props.controlPosition === 20 && this.state.inputPosition !== 20) {
      this.setState({ inputPosition: 20 });
    }

    if (
      this.props.activeTab === "labor" &&
      this.props?.activeTab !== prevProps?.activeTab
    ) {
      this.loadDeaultData();
    }
  };

  loadDeaultData = () => {
    this.getEmployeeTypes();
    this.getPTOUserList();
    this.getACATracking();
    this.getUserReviewSchedule();
    this.getHotelWisePayrollData();
    this.getPayrollAllowanceData();
    this.getUserSpecialRates();
  };

  showHidePerformanceReviewApprovalModal = (
    isReload = false,
    row: any = {}
  ) => {
    if (isReload) {
      this.getUserReviewSchedule();
    }
    this.setState({ performanceReviewAprrovalRow: row }, () => {
      this.setState({
        addPerformanceReviewApproval: !this.state?.addPerformanceReviewApproval,
      });
    });
  };

  getUserReviewSchedule = () => {
    UserManagement.GetUserReviewSchedule(+this.props?.uniqueno, 0).then(
      (performanceReviews: any[]) => {
        const performanceReviewData: any[] = [];
        if (performanceReviews?.length > 0) {
          const data = performanceReviews.map((item) => {
            item.nextDueDate = moment(item.nextDueDate)?.format("MM/DD/YY");
            return item;
          });

          const completeData: any[] = _.orderBy(
            data.filter((x) => x?.status === "Complete"),
            (item) => {
              return new Date(item.nextDueDate);
            },
            ["desc"]
          );

          const inCompleteData: any[] = _.orderBy(
            data.filter((x) => x?.status !== "Complete"),
            (item) => {
              return new Date(item.nextDueDate);
            },
            ["asc"]
          );

          if (inCompleteData?.length > 0) {
            this.setState({
              performanceSectionTableExpended: [2],
              isPerformanceReviewSectionExpand: true,
            });
            performanceReviewData.push({
              id: 2,
              labelName: "Incomplete",
              data: inCompleteData,
            });
          }
          if (completeData?.length > 0) {
            performanceReviewData.push({
              id: 1,
              labelName: "Complete",
              data: completeData,
            });
          }
        }
        this.setState({ performanceReviews: [] }, () => {
          this.setState({ performanceReviews: performanceReviewData });
        });
      }
    );
  };

  setBulHotelBulkPayHours = () => {
    const { hotelWideTableData } = this.state;
    let hotelWisePayrollBulkData: any[] = [];

    if (hotelWideTableData.length > 0) {
      hotelWisePayrollBulkData = hotelWideTableData
        .filter((item) => item?.status === "Active")
        .map((item: any) => ({
          uniqueNo: item?.uniqueNo || "",
          hid: +item?.hid || 0,
          lettercode: item?.lettercode,
          status: item?.status || "Active",
          coa: item?.coa || 0,
          regularRate:
            +item?.salaried > 0 && +item?.annualSal > 0
              ? +item?.regularRate
              : 0,
          payRate: item?.payRate || "",
          annualSal: item?.annualSal
            ? Utils.currencyFormat(item?.annualSal)
            : item?.annualSal,

          terDate: item?.terDate,
          unemployeeState: item?.unemploymentState || "",
          withHoldingState: item?.withholdingState || "",
          stateFilling: item?.stateFillingStatus || "",
          empCode: item?.empLoyee_Code || "",
          totalHrs: item?.salaried
            ? item?.salaried?.toString()?.replace(".", ":")
            : "",
          isModified:
            +item?.totalHrs?.toString()?.replace(":", ".") > 0 &&
            +item?.annualSal > 0
              ? true
              : false,
          isPrimary_Property:
            typeof item?.isPrimary_Property === "boolean"
              ? item?.isPrimary_Property
              : null,
        }));
    }

    this.setState({ hotelWisePayrollBulkData });
  };

  getPayrollAllowanceData = () => {
    this.setState({ allowanceTableData: [], isAllowancePayrollLoaded: false });
    UserManagement.GetPayrollAllowancesList(+this.props?.uniqueno)
      .then((res) => {
        this.setState({
          allowanceTableData: res,
          isAllowancePayrollLoaded: true,
        });
      })
      .catch((err) => {
        this.setState({
          allowanceTableData: [],
          isAllowancePayrollLoaded: false,
        });
      });
  };

  getHotelWisePayrollData = (tabName = "") => {
    this.setState({ hotelWideTableData: [], isHotelWisePayrollLoaded: false });
    (this.props?.userGeneralInfo?.employeeType !== "ExcludefromPayroll"
      ? UserManagement.GetHotelWisePayroll(
          +this.props?.uniqueno,
          this.props?.isNewUserAdded
        )
      : UserManagement.GetHotelWisePayrollForExcludeFromPayroll(
          +this.props?.uniqueno,
          this.props?.isNewUserAdded
        )
    ).then((hotelWideTableData) => {
      if (hotelWideTableData?.length > 0) {
        hotelWideTableData = hotelWideTableData.map((x) => {
          x.empType1 = this.props?.userGeneralInfo?.employeeTypeDisplay;
          return x;
        });
      }

      this.setState(
        { hotelWideTableData, isHotelWisePayrollLoaded: true },
        () => {
          this.setBulHotelBulkPayHours();
          this.validateLaborSettingFormSchema();
        }
      );
    });
  };

  checkFormModified(isLoadedFromApi = false) {
    const { laborSettings, prevLaborSettings } = this.state;

    let _isLaborSettingNotChanged = _.isEqual(laborSettings, prevLaborSettings);
    if (isLoadedFromApi) {
      _isLaborSettingNotChanged = _.isEqual(laborSettings, prevLaborSettings);
    }

    this.setState({ isLaborSettingNotChanged: _isLaborSettingNotChanged });
    this.props.getLaborTabModificationStatus(!_isLaborSettingNotChanged);
  }
  componentWillUnmount = () => {
    // setTimeout(() => {
    //   toast.dismiss();
    // }, 1000)
  };

  handleDiscard = () => {
    this.props.getLaborTabModificationStatus(false);
    this.props.getUserGeneralInfo(true);
  };

  calculateLaborTabCount = () => {
    if (
      !this.state.isHotelWisePayrollLoaded &&
      !this.state.isAllowancePayrollLoaded
    ) {
      return;
    }
    if (!this.state?.laborSettings.employeeType) {
      this.props?.getLaborTabCount(1);
      return;
    }
    let errorLength = Object.keys(this.state?.laborSettingsErrors)?.length;

    if (this.state?.laborSettings.employeeType === "ExcludefromPayroll") {
      this.props?.getLaborTabCount(
        !this.state?.laborSettings.employeeType ? errorLength + 1 : errorLength
      );
      return;
    }

    if (
      this.state.hasViewSensitiveDataPermission === false &&
      errorLength === 1 &&
      this.state?.laborSettingsErrors?.dOB
    ) {
      errorLength = 0;
    }

    let PTOCount =
      this.state.laborTimeOffData.filter((x) => +x?.status > 0).length === 0 &&
      this.state.isAllowPTO
        ? 1
        : 0;
    if (
      this.props?.userGeneralInfo?.employeeType === "Contractor" ||
      this.props?.userGeneralInfo?.employeeType === "ExcludefromPayroll"
    ) {
      PTOCount = 0;
    }
    this.props?.getLaborTabCount(
      (this.props?.userGeneralInfo?.employeeType !== "ExcludefromPayroll" &&
      this.props?.userGeneralInfo?.employeeType &&
      this.props?.userGeneralInfo?.groupHWPPayrollSetting
        ? 1
        : 0) +
        errorLength +
        PTOCount
    );
  };
  validateLaborSettingFormSchema = () => {
    const valid = Joi.validate(
      this.state.laborSettings,
      this.state.laborSettings?.employeeType === "Contractor"
        ? this.laborSettingFormSchemaContractor
        : this.laborSettingFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    if (this.state.laborSettings?.employeeType === "ExcludefromPayroll") {
      if (newErrorObject["dOB"]) {
        delete newErrorObject["dOB"];
      }
    }
    if (newErrorObject["averageHoursWorked"]) {
      delete newErrorObject["averageHoursWorked"];
    }

    if (
      this.state.laborSettings.dOB &&
      this.state.laborSettings?.employeeType !== "ExcludefromPayroll"
    ) {
      var splittedDate = this.state.laborSettings.dOB?.toString()?.split("/");
      const hireDate =
        this.props?.userGeneralInfo.hireDate &&
        this.props?.userGeneralInfo.hireDate != "0001-01-01T00:00:00"
          ? moment(this.props?.userGeneralInfo.hireDate).format("MM/DD/YY")
          : "";
      if (
        moment(this.state.laborSettings.dOB)?.format("MM/DD/YYYY") ===
          "Invalid date" ||
        this.state.laborSettings.dOB?.toString()?.length !== 10 ||
        splittedDate[0]?.toString()?.length !== 2 ||
        splittedDate[1]?.toString()?.length !== 2 ||
        splittedDate[2]?.toString()?.length !== 4
      ) {
        newErrorObject["dOB"] = "Date of Birth should be in MM/DD/YYYY format";
      } else if (
        hireDate &&
        moment(this.state.laborSettings.dOB).isAfter(moment(hireDate))
      ) {
        newErrorObject["dOB"] = "Date of Birth should be less than Hire Date";
      } else {
        if (newErrorObject["dOB"]) {
          delete newErrorObject["dOB"];
        }
      }
    }

    if (this.state.hasViewSensitiveDataPermission === false) {
      if (newErrorObject["dOB"]) {
        delete newErrorObject["dOB"];
      }
      if (newErrorObject["ssn"]) {
        delete newErrorObject["ssn"];
      }
    }
    if (this.state.laborSettings?.employeeType !== "Contractor") {
      // if (newErrorObject["ssn"]) {
      //   delete newErrorObject["ssn"];
      // }
      if (newErrorObject["vendorId"]) {
        delete newErrorObject["vendorId"];
      }
      if (newErrorObject["contractId"]) {
        delete newErrorObject["contractId"];
      }
      if (newErrorObject["paidByVendor"]) {
        delete newErrorObject["paidByVendor"];
      }
      if (newErrorObject["allowContactorOverTime"]) {
        delete newErrorObject["allowContactorOverTime"];
      }
    }

    if (
      this.state.laborSettings?.paidByVendor === 1 &&
      this.state.laborSettings?.employeeType === "Contractor"
    ) {
      if (newErrorObject["vendorId"]) {
        delete newErrorObject["vendorId"];
      }
    }

    if (
      this.state.laborSettings?.paidByVendor === 2 &&
      +this.state.laborSettings?.vendorId <= 0 &&
      this.state.laborSettings?.employeeType === "Contractor"
    ) {
      newErrorObject["vendorId"] = "Please select Vendor/Agency";
    }

    if (
      newErrorObject?.ssn == "Please enter SSN" &&
      this.state.laborSettings?.employeeType == "ExcludefromPayroll"
    ) {
      delete newErrorObject.ssn;
    }

    const isPayrollTabAccessible = this.props.checkHasPayrollModuleAccess();
    if (
      newErrorObject?.ssn == "Please enter SSN" &&
      !(this.state.payrollIntegratedAtLeastOneHid || isPayrollTabAccessible)
    ) {
      delete newErrorObject.ssn;
    }

    this.setState({ laborSettingsErrors: newErrorObject }, () => {
      if (this.countTimer) {
        clearTimeout(this.countTimer);
      }
      this.countTimer = setTimeout(() => {
        this.calculateLaborTabCount();
      }, 1000);
    });

    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormValid: true });
    } else {
      this.setState({ isFormValid: false });
    }
    return newErrorObject;
  };

  validationOnBlur = (inputPosition) => {
    if (!(this.props.isEditLaborandPayrollInfo || this.props?.isNewUserAdded)) {
      return;
    }
    this.setState({ inputPosition });
    this.validateLaborSettingFormSchema();
  };
  getEmployeeTypes = () => {
    UserManagement.GetPayTypeList().then((response: any[]) => {
      const employeeTypes = _.sortBy(
        response.map((item) => ({ id: item.value, name: item.name })),
        "name"
      );
      this.setState({ employeeTypes });
    });
  };

  deletePayrollSpecialRate = (row) => {
    UserManagement.DeletePayrollSpecialRate(row?.uniqueno).then((result) => {
      this.getUserSpecialRates();
      toast.success("Special Rate deleted successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "labortabcontainer",
      });
    });
  };

  getACATracking = () => {
    UserManagement.GetACATracking(this.props?.uniqueno).then(
      (laborACATracking: any[]) => {
        laborACATracking = laborACATracking.map((item) => {
          item.startDate = item.startDate
            ? moment(item?.startDate).format("MM/DD/YYYY")
            : "";
          return item;
        });
        this.setState({ laborACATracking });
      }
    );
  };

  getPTOUserList = (isMainDataRefreshRequired = false) => {
    const { username } = this.props;
    this.setState({ isPTOUserListLoaded: false });
    UserManagement.GetPTOUserList(username)
      .then((data: IPTOUserDetails[]) => {
        const laborTimeOffData = data?.map((item) => {
          item.ptoStartDate = item.ptoStartDate
            ? moment(item?.ptoStartDate).format("MM/DD/YYYY")
            : "";
          item.ptoStartingBalance = Utils.getMinutesInHHMMFormat(
            Utils.getDecimalHHMMToMinutes(item.ptoStartingBalance)
          );
          return item;
        });
        this.setState({ laborTimeOffData }, () => {
          UserManagement.GetPTOName("Active", username)
            .then((ptoNames) => {
              debugger;

              const existingPTONames = laborTimeOffData.map(
                (item) => item.ptoName
              );

              if (existingPTONames?.length > 0) {
                ptoNames = ptoNames.filter(
                  (item) => !existingPTONames.includes(item.ptoName)
                );
              }
              this.setState({ ptoNames });
            })
            .finally(() => this.setState({ isPTONamesLoaded: true }));

          this.calculateLaborTabCount();
        });
      })
      .finally(() => this.setState({ isPTOUserListLoaded: true }));
  };

  onOutsideClick = (e: any) => {};
  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    if (this.state.tableExpended.length > 0) {
      if (isExpand) {
        this.setState({
          isExpand: true,
          ptoRow: row,
          tableExpended: [row.ptoUniqueNo],
        });
        this.props?.handlePTOTableExpended([row.ptoUniqueNo])
        this.getPTOAdjustedDetails(row.ptoUniqueNo);
      }
      else{
        this.setState(
            {
              ptoRow: {},
              // laborTimeOffSubData: [],
              isExpand: false,
              tableExpended: [],
            })
        this.props?.handlePTOTableExpended([])
      }
    } else {
      this.setState({
        isExpand: true,
        ptoRow: row,
        tableExpended: [row.ptoUniqueNo],
      });
      this.props.handlePTOTableExpended([row.ptoUniqueNo])
      this.getPTOAdjustedDetails(row.ptoUniqueNo);
    }
  };

  handleOnPerformanceReviewSectionExpand = (
    row: any,
    isExpand: any,
    rowIndex: any,
    e: any
  ) => {
    let performanceSectionTableExpended =
      [...this.state.performanceSectionTableExpended] || [];
    if (isExpand) {
      this.setState({
        isPerformanceReviewSectionExpand: true,
        performanceSectionTableExpended: [
          row.id,
          ...performanceSectionTableExpended,
        ],
      });
    } else {
      performanceSectionTableExpended = performanceSectionTableExpended.filter(
        (x) => x !== row.id
      );
      this.setState({
        isPerformanceReviewSectionExpand: false,
        performanceSectionTableExpended,
      });
    }
  };
  handleOnPerformanceReviewExpand = (
    row: any,
    isExpand: any,
    rowIndex: any,
    e: any
  ) => {
    if (!row.description) {
      this.setState({
        performanceRow: {},
        performanceReviewComments: [],
        isExpand: false,
        performanceTableExpended: [],
      });
      return;
    }
    if (this.state.performanceTableExpended.length > 0) {
      this.setState(
        {
          performanceRow: {},
          performanceReviewComments: [],
          isExpand: false,
          performanceTableExpended: [],
        },
        () => {
          if (isExpand) {
            this.setState(() => ({
              isExpand: true,
              performanceRow: row,
              performanceTableExpended: [row.scheduleId],
            }));
            this.getPerformanceReviewComments(row.scheduleId, row.userUniqueno);
          }
        }
      );
    } else {
      this.setState(() => ({
        isExpand: true,
        performanceRow: row,
        performanceTableExpended: [row.scheduleId],
      }));
      this.getPerformanceReviewComments(row.scheduleId, row.userUniqueno);
    }
  };

  getPerformanceReviewComments = (scheduleId, userUniqueno) => {
    UserManagement.GetUserReviewDetails(scheduleId, userUniqueno).then(
      (response) => {
        this.setState({ performanceReviewComments: response?.result?.result });
      }
    );
  };

  handleOnHotelWideExpand = (
    row: any,
    isExpand: any,
    rowIndex: any,
    e: any
  ) => {
    debugger;
    if (row?.status === "Active") {
      if (this.state.hotelWideTableExpended.length > 0) {
        if (isExpand) {
          this.setState({
            isHotelWideExpand: true,
            hotelWideRow: row,
            hotelWideTableExpended: [row.hid],
          });
          this.props?.handleSetHotelWideTableExpended([row.hid])
        }
        else{
          this.setState(
              {
                hotelWideRow: {},
                isHotelWideExpand: false,
                hotelWideTableExpended: [],
              })
           this.props?.handleSetHotelWideTableExpended([])
        }
      } else {
        this.setState({
          isHotelWideExpand: true,
          hotelWideRow: row,
          hotelWideTableExpended: [row.hid],
        });
        this.props.handleSetHotelWideTableExpended([row.hid])
      }
    } else {
      this.setState({
        hotelWideTableExpended: [],
      });
      this.props.handleSetHotelWideTableExpended([])
    }
  };

  handleOnAllowanceExpand = (
    row: any,
    isExpand: any,
    rowIndex: any,
    e: any
  ) => {
    if (this.state.allowanceTableExpanded.length > 0) {
      this.setState(
        {
          allowanceRow: {},
          isAllowanceExpand: false,
          allowanceTableExpanded: [],
        },
        () => {
          if (isExpand) {
            this.setState({
              isAllowanceExpand: true,
              allowanceRow: row,
              allowanceTableExpanded: [row.hid],
            });
          }
        }
      );
    } else {
      this.setState({
        isAllowanceExpand: true,
        allowanceRow: row,
        allowanceTableExpanded: [row.hid],
      });
    }
  };

  getPTOAdjustedDetails = (ptoType: any) => {
    const { username } = this.props;
    UserManagement.GetPTOAdjustedDetails(ptoType, username).then(
      (data: ILaborPTOAdjBalance[]) => {
        const laborTimeOffSubData = data?.map((item: any) => {
          item.dateFormat = moment(item.date)?.format("MM/DD/YYYY");
          item.date = item?.date
            ? moment(item.date)?.format("MM/DD/YYYY [at] HH:MM")
            : "";
          item.adjPTO =
            (item.adjPTO?.toString()?.includes("-") ? "-" : "") +
            Utils.getMinutesInHHMMFormat(
              Utils.getDecimalHHMMToMinutes(item.adjPTO)
            );
          return item;
        });
        this.setState({ laborTimeOffSubData });
      }
    );
  };

  handleShowACACodeEdit = (acaTableRow) => {
    this.setState({ showACACodeEdit: true, acaTableRow });
  };

  handleCloseACACodeEdit = (shouldReload = false) => {
    if (shouldReload) {
      this.getACATracking();
    }
    this.setState({ showACACodeEdit: false, acaTableRow: {} });
  };

  handleShowACAUpload = (acaTableRow) => {
    this.setState({ showACAUpload: true, acaTableRow });
  };

  handleCloseACAUpload = (shouldReload = false) => {
    this.setState({ showACAUpload: false, acaTableRow: {} });
  };

  onHourEntryBlur = (value, index) => {
    const { hotelWisePayrollBulkData } = this.state;
    value = value?.toString()?.replace(".", "");
    if (value?.toString()?.includes(":")) {
      const valueArray = value?.split(":");
      if (
        valueArray[0]?.length === 1 ||
        valueArray[1]?.length === 1 ||
        !valueArray[1]
      ) {
        value =
          (valueArray[0]?.toString()?.padStart(2, "0") || "00") +
          ":" +
          (valueArray[1]?.toString()?.padStart(2, "0") || "00");
      }
    } else {
      if (value) {
        value = (value.toString()?.padStart(2, "0") || "00") + ":" + "00";
      }
    }
    hotelWisePayrollBulkData[index].totalHrs = value;

    this.setState({ hotelWisePayrollBulkData, inputPosition: 2 }, () =>
      this.validateLaborSettingFormSchema()
    );
  };

  updateUserHotelsData = (value, fieldName, index, isBlur = false) => {
    const { hotelWisePayrollBulkData } = this.state;
    const _hotelWisePayrollBulkData = _.cloneDeep(hotelWisePayrollBulkData);
    if (fieldName === "totalHrs") {
      value = value?.toString()?.replace(".", "");
      if (
        value?.toString()?.length === 2 &&
        value?.toString()?.length >
          _hotelWisePayrollBulkData[index]?.totalHrs?.toString()?.length
      ) {
        value = value + ":";
      }

      const altValue =
        value !== undefined && _hotelWisePayrollBulkData[index][fieldName]
          ? value
          : _hotelWisePayrollBulkData[index][fieldName];
      if (!Utils.GetHHMMValidString(value) && value) {
        return;
      }
      value = Utils.GetHHMMValidString(value) || altValue;

      if (value?.toString()?.includes(":")) {
        const valueArray = value?.split(":");

        if (+valueArray[1] > 59) {
          value =
            valueArray[0]?.toString() +
            ":" +
            valueArray[1]?.toString()?.substr(0, 1);
        }
      }
    }
    if (
      fieldName === "annualSal" &&
      value?.toString()?.split(".")[1]?.length > 2
    ) {
      return;
    }
    if (fieldName == "annualSal") {
      value = Utils.removeInvaildCharFromAmount(value, 20);
    }

    _hotelWisePayrollBulkData[index][fieldName] = value;

    let annualSal = _hotelWisePayrollBulkData[index].annualSal
      ? +Utils.removecurrencyFormat(_hotelWisePayrollBulkData[index].annualSal)
      : 0;
    if (
      +_hotelWisePayrollBulkData[index].totalHrs
        ?.toString()
        ?.replace(":", ".") > 0 &&
      +annualSal > 0
    ) {
      _hotelWisePayrollBulkData[index].isModified = true;
    } else {
      _hotelWisePayrollBulkData[index].isModified = false;
    }

    if (
      +_hotelWisePayrollBulkData[index].totalHrs
        ?.toString()
        ?.replace(":", ".") >= 0 &&
      +annualSal >= 0
    ) {
      const totalMinutes =
        +_hotelWisePayrollBulkData[index]?.totalHrs
          ?.toString()
          ?.replace(":", ".") > 0
          ? Utils.getMinutesFromHHMM(
              _hotelWisePayrollBulkData[index]?.totalHrs || "0"
            )
          : 0;

      _hotelWisePayrollBulkData[index].regularRate = (
        annualSal /
        (Utils.getMinutesInHHMMFormatDot(totalMinutes) * 52)
      ).toFixed(2);

      _hotelWisePayrollBulkData[index].regularRate =
        _hotelWisePayrollBulkData[index].regularRate === "Infinity"
          ? 0
          : _hotelWisePayrollBulkData[index].regularRate;
    }

    this.setState(
      { hotelWisePayrollBulkData: _hotelWisePayrollBulkData, inputPosition: 2 },
      () => {
        this.validateLaborSettingFormSchema();
        this.checkFormModified();
      }
    );
  };

  onAmountFocus = (event: any, index) => {
    debugger;
    let newval =
      !event.target.value ||
      +event.target.value === 0 ||
      event.target.value === "$0.00"
        ? ""
        : Utils.removecurrencyFormat(event.target.value);
    const { hotelWisePayrollBulkData } = this.state;
    const _hotelWisePayrollBulkData = _.cloneDeep(hotelWisePayrollBulkData);
    _hotelWisePayrollBulkData[index].annualSal = newval;
    this.setState({ hotelWisePayrollBulkData: _hotelWisePayrollBulkData });
  };

  handleAnnualSalaryFormat = (event, index) => {
    const { hotelWisePayrollBulkData } = this.state;
    const _hotelWisePayrollBulkData = _.cloneDeep(hotelWisePayrollBulkData);
    if (!isNaN(event.target.value)) {
      let annualSal = Utils.currencyFormat(
        _hotelWisePayrollBulkData[index].annualSal
      );
      _hotelWisePayrollBulkData[index].annualSal = annualSal;
    }
    this.setState({ hotelWisePayrollBulkData: _hotelWisePayrollBulkData });
  };

  onLaborSettingsFieldChange = (event, inputPosition, isBlur = false) => {
    let value = event.target.value;
    const fieldName = event.target.name;
    const { laborSettings } = this.state;
    if (
      fieldName === "ssn" &&
      value?.toString()?.length > laborSettings?.ssn?.toString()?.length
    ) {
      var pattern = /^[0-9\-]+$/;
      if (!pattern.test(value)) {
        return;
      }
    }
    const _laborSettings = _.cloneDeep(this.state.laborSettings);
    if (
      fieldName === "averageHoursWorked" &&
      value?.toString()?.length >
        laborSettings?.averageHoursWorked?.toString()?.length
    ) {
      var pattern = /^[0-9\.]+$/;
      if (!pattern.test(value)) {
        return;
      }
    }

    if (fieldName === "averageHoursWorked") {
      if (value?.toString()?.split(".")[1]?.length > 2) {
        return;
      }

      if (value?.toString()?.includes("-")) {
        value = Number(value.toString()?.replace("-", ""));
      }
      if (+value > 40) {
        return;
      }
      if (value?.toString()?.includes(".")) {
        const valueArray = value?.split(".");

        if (+valueArray[1] > 59) {
          value = Number(
            valueArray[0]?.toString() +
              "." +
              valueArray[1]?.toString()?.substr(0, 1)
          );
          return;
        }
      }
    }

    if (value === "Salaried" || value === "SalariedNonExempt") {
      this.openSalariedHoursEntryPanel(value);
      const totalMinutes = _laborSettings.averageHoursWorked
        ? Utils.getMinutesFromHHMM(value)
        : 0;

      if (totalMinutes / 60 > +this.props?.clientSetupData?.exceedHours) {
        return;
      }
    }

    if (fieldName === "employeeType") {
      if (value === "ExcludefromPayroll") {
        if (
          laborSettings?.employeeType !== "ExcludefromPayroll" &&
          this.state?.laborTimeOffData?.length > 0
        ) {
          this.confirmationOnSlideoutClose(
            "employeeType",
            value,
            inputPosition
          );
        }
        laborSettings["dOB"] = ""
        this.setState({ showHideDOBControl: false });
        
      } else {
        laborSettings["dOB"] = this.props?.userGeneralInfo?.dob || "";
        this.setState({ showHideDOBControl: true });
      }
    }
    if (fieldName === "employeeType") {
      if (
        value === "ExcludefromPayroll" &&
        this.state?.laborTimeOffData?.length > 0
      ) {
        return;
      }
    } else {
      if (this.state?.doesPolicyDeactivationRequired) {
        this.setState({ doesPolicyDeactivationRequired: false });
      }
    }

    if (fieldName === "dOB") {
      const _formattedDate = Utils.AddSlashINInputDateFormat(
        value,
        laborSettings?.dOB,
        true,
        4,
        isBlur
      );
      if (_formattedDate === "invalid") {
        return;
      } else {
        value = _formattedDate;
      }
    }

    if (fieldName === "ssn") {
      const _formattedValue = Utils.AddHypenSSNFormat(
        value,
        laborSettings?.ssn
      );
      if (_formattedValue === "invalid") {
        return;
      } else {
        value = _formattedValue;
      }
    }

    laborSettings[fieldName] = value;
    this.setState({ laborSettings, inputPosition }, () => {
      this.validateLaborSettingFormSchema();
      if (!isBlur) {
        this.checkFormModified();
      }
    });
  };
  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { laborSettingsErrors } = this.state;

    return (
      <>
        {Object.keys(laborSettingsErrors).length > 0 &&
          fieldPosition <= this.state.inputPosition && (
            <span className="validation-message">
              {laborSettingsErrors[fieldName]}
            </span>
          )}
      </>
    );
  };

  checkHasTabAccess = (tabName) => {
    const { userGeneralInfo } = this.props;
    let hasAccess = false;
    if (userGeneralInfo?.subscriptionModule?.length > 0) {
      hasAccess = userGeneralInfo?.subscriptionModule
        .map((item) => item.result?.toString()?.toLowerCase())
        .includes(tabName?.toString()?.toLowerCase());
    }
    return hasAccess;
  };
  buildCustomEventObject = (name, value) => {
    const valueObj: any = {
      target: {
        name,
        value,
      },
    };

    return valueObj;
  };

  openSalariedHoursEntryPanel = (employeeType) => {
    const { laborSettings } = this.state;
    if (
      this.state.hotelWisePayrollBulkData?.length > 0 &&
      (employeeType === "Salaried" || employeeType === "SalariedNonExempt") &&
      this.props?.userGeneralInfo?.employeeType !== "Salaried" &&
      this.props?.userGeneralInfo?.employeeType !== "SalariedNonExempt"
    ) {
      this.setState({ showSalariedHoursEntryPanel: true });
    }
  };

  getUserPerformanceReviewIsDeleted = (row) => {
    UserManagement.GetUserReviewDetails(
      row?.scheduleId,
      this.props?.uniqueno
    ).then((response) => {
      if (this.state?.performanceReviewComments?.length == 0) {
        UserManagement.GetUserPerformanceReviewIsDeleted(
          row?.scheduleId,
          this.props?.uniqueno
        ).then((result) => {
          if (result?.result?.success) {
            this.getUserReviewSchedule();
            toast.success(result?.result?.result, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "labortabcontainer",
            });
          } else {
            Utils.toastError(result?.result?.result, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "labortabcontainer",
            });
          }
        });
      } else {
        Utils.toastError(
          "Cannot delete as this Performance Review has exisiting comments",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "labortabcontainer",
          }
        );
      }
    });
  };

  closeSalariedHoursEntryPanel = (isClose = false) => {
    if (isClose) {
      this.setBulHotelBulkPayHours();
      this.handleDiscard();
    }

    this.setState({ showSalariedHoursEntryPanel: false });
  };

  submitLaborData = () => {
    const laborSettings = _.cloneDeep(this.state.laborSettings);
    const {
      username,
      userGeneralInfo: { user_UniqueId: userUniqueId },
    } = this.props;
    const request: ILaborSettings = {
      userUniqueId,
      username,
      loginUser: "",
      ...laborSettings,
    } as ILaborSettings;

    request.isNewUserAdded = this.props?.isNewUserAdded;
    this.setState({ isDataSubmitting: true });
    UserManagement.SaveLaborSettingAndPayHours(request)
      .then((result) => {
        this.setState({ isDataSubmitting: false });
        if (result?.message === "Success") {
          if (
            result?.result.message === "Fail"
            // && request.employeeType === "Contractor"
          ) {
            if (typeof result?.result?.result === "string") {
              Utils.toastError(result?.result?.result, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "labortabcontainer",
              });
            } else {
              this.setState(
                { duplicateSSNTableData: result?.result?.result || [] },
                () => {
                  this.handleShowDuplicateSSNModal();
                }
              );
            }
            return;
          }
          const { laborSettings } = this.state;
          this.setState({ prevLaborSettings: _.cloneDeep(laborSettings) });
          toast.success(result?.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "labortabcontainer",
          });
          this.props.getLaborTabModificationStatus(false);
          this.props.getUserGeneralInfo(true);
          if (this.state?.doesPolicyDeactivationRequired) {
            this.deActiveBulkPTOPoliciesStatus();
          }
        } else {
          Utils.toastError(result?.result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "labortabcontainer",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSubmitting: false });
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "labortabcontainer",
        });
      })
      .finally(() =>
        this.setState({ isLaborSettingNotChanged: false, isFormValid: false })
      );
  };

  closeExcludeFromPayrollWarning = () => {
    this.setState({ showHideExcludeFromPayroll: false });
  };
  showExcludeFromPayrollWarning = () => {
    this.setState({ showHideExcludeFromPayroll: true });
  };

  hideHotelWiseAddEditModalForm = (isReload = false) => {
    this.setState({ showHotelWiseAddEditModal: false }, () => {
      if (isReload) {
        this.getHotelWisePayrollData();
        this.props?.getUserGeneralInfo(true);
      }
    });
  };

  hideAllowanceAddEditModalForm = (isReload = false) => {
    this.setState({ showAllowanceAddEditModal: false }, () => {
      if (isReload) {
        this.getPayrollAllowanceData();
        this.props?.getUserGeneralInfo(true);
      }
    });
  };

  showHotelWiseAddEditModalForm = (
    isEdit: boolean = false,
    row: any = {},
    isHotelWideSettingMissing: boolean = false
  ) => {
    const { userGeneralInfo } = this.props;
    const { hotelWideTableData, laborSettings } = this.state;
    if (isEdit) {
      if (
        this.props?.userGeneralInfo.hids?.filter(
          (item) => +item?.hotelID === +row?.hid
        )?.length === 0
      ) {
        toast.success(
          "You are unable to open and edit the information because you do not have access to the E/H/NID",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "labortabcontainer",
          }
        );
        return;
      }

      this.getUserSpecialRates();

      this.setState({ hotelWisePayrollRowEdit: row }, () => {
        this.setState({ showHotelWiseAddEditModal: true });
      });
    } else {
      this.setState(
        {
          hotelWisePayrollRowEdit: {},
        },
        () => {
          this.setState({ showHotelWiseAddEditModal: true });
        }
      );
    }
  };

  showAllowanceAddEditModalForm = (
    isEdit: boolean = false,
    row: any = {},
    isHotelWideSettingMissing: boolean = false
  ) => {
    const { userGeneralInfo } = this.props;
    const { hotelWideTableData, laborSettings } = this.state;
    if (isEdit) {
      if (
        this.props?.userGeneralInfo.hids?.filter(
          (item) => +item?.hotelID === +row?.hid
        )?.length === 0
      ) {
        toast.success(
          "You are unable to open and edit the information because you do not have access to the E/H/NID",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "labortabcontainer",
          }
        );
        return;
      }

      this.getUserSpecialRates();

      this.setState({ AllowancePayrollRowEdit: row }, () => {
        this.setState({ showAllowanceAddEditModal: true });
      });
    } else {
      this.setState(
        {
          AllowancePayrollRowEdit: {},
        },
        () => {
          this.setState({ showAllowanceAddEditModal: true });
        }
      );
    }
  };

  hideSpecialRateAddEditModalForm = (isReload = false) => {
    this.setState({ showSpecialRateAddEditModal: false }, () => {
      if (isReload) {
        this.getUserSpecialRates();
      }
    });
  };

  downloadACACodeFiles = (): void => {
    window.location.href = "../../files/ACAStatusCode.zip";
  };

  showSpecialRateAddEditModalForm = (
    isEdit: boolean = false,
    row: any = {}
  ) => {
    if (isEdit) {
      this.setState({ specialRateRowEdit: row }, () => {
        this.setState({ showSpecialRateAddEditModal: true });
      });
    } else {
      this.setState(
        {
          specialRateRowEdit: {},
        },
        () => {
          this.setState({ showSpecialRateAddEditModal: true });
        }
      );
    }
  };

  confirmUpdateStatus = (event) => {
    var checked = event.target.checked;
    var hasactive = this.state.laborTimeOffData.filter((x) => x.status == 1);
    if (!checked && hasactive?.length > 0) {
      confirmAlert({
        title: "Inactive confirmation",
        message:
          "If you turn off 'Paid Time Off' then all active PTO policies will be inactivated. Are you sure you want to continue?",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.onIsAllowPTOChange(checked),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else {
      this.onIsAllowPTOChange(checked);
    }
  };

  updateStatus = (row) => {
    row.ptoStatus = +row.status === 1 ? 0 : 1;
    row.ptoName = row?.ptoUniqueNo;
    const request: ISavePTOUserDetailRequest = {
      ...row,
    } as ISavePTOUserDetailRequest;
    request.isNewUserAdded = this.props?.isNewUserAdded;
    UserManagement.SavePTOUserDetails(request)
      .then((result) => {
        if (result?.message.message === "Success") {
          toast.success("PTO Status updated successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "labortabcontainer",
          });
          this.getPTOUserList(true);
        } else {
          Utils.toastError(result?.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "labortabcontainer",
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
  confirmPoliciesDeactivation = (key, value, inputPosition) => {
    const { laborSettings } = this.state;
    laborSettings[key] = value;

    this.setState(
      { laborSettings, inputPosition, doesPolicyDeactivationRequired: true },
      () => {
        this.validateLaborSettingFormSchema();
        this.checkFormModified();
      }
    );
  };

  deActiveBulkPTOPoliciesStatus = () => {
    const { username } = this.props;
    UserManagement.UpdateBulkPTOPoliciesStatus(
      username,
      this.props?.isNewUserAdded,
      0
    )
      .then((result) => {
        if (result?.message === "Success") {
          this.getPTOUserList();

          this.setState({ doesPolicyDeactivationRequired: false });
        } else {
          Utils.toastError(result?.result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "labortabcontainer",
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "labortabcontainer",
        });
      });
  };

  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = (): any => {
    this.setState({ calenderShow: true });
  };
  handleAcaActions = (item, row) => {
    if (item === "Edit") {
      this.handleShowACACodeEdit(row);
    } else if (item === "Upload") {
      this.handleShowACAUpload(row);
    }
  };

  getDetailsData = (ptoType) => {
    this.getPTOUserList(true);
    this.getPTOAdjustedDetails(ptoType);
  };

  confirmationOnSlideoutClose = (key, value, inputPosition) => {
    confirmAlert({
      title: "Are you sure?",
      message:
        "Changing this user to excluded from payroll will remove any mapped PTO policies. Are you sure you want to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.confirmPoliciesDeactivation(key, value, inputPosition),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  addNewPerformanceReview = () => {
    if (this.state.addPerformanceReview) {
      this.setState({ addPerformanceReview: false });
    }
    setTimeout(() => {
      this.setState({ addPerformanceReview: true });
    });
  };

  hidePerformanceReviewModal = (isReloadRequired = false) => {
    if (isReloadRequired) {
      this.getUserReviewSchedule();
    }
    this.setState({ addPerformanceReview: false });
  };

  getUserSpecialRates = () => {
    this.setState({ isSpecialRateLoaded: false });
    UserManagement.GetUserSpecialRatesAllHid(this.props?.uniqueno)
      .then((spacialRates) => {
        const data = spacialRates.map((item: any) => {
          item.wefDate = moment(item.wefDate)?.format("MM/DD/YYYY");
          return item;
        });
        this.setState({ spacialRates: data }, () => {
          this.setState({ isSpecialRateLoaded: true });
        });
      })
      .catch(() => this.setState({ isSpecialRateLoaded: true }));
  };
  updateSalesToggle = (isReloadPTO = false, isReloadPerformace = false) => {
    const { isAllowPTO, isAllowACATracking, isAllowPerformance } = this.state;
    const allowDeposit = this.props?.userGeneralInfo?.allowDeposit;
    UserManagement.UpdateToggle(
      this.props?.username,
      this.props?.userGeneralInfo?.isSalesAgent,
      isAllowPTO ? 1 : 0,
      isAllowACATracking ? 1 : 0,
      isAllowPerformance ? 1 : 0,
      allowDeposit == null ? -1 : allowDeposit
    ).then((result) => {
      if (isReloadPTO) {
        this.getPTOUserList();
      }
      if (isReloadPerformace) {
        this.getUserReviewSchedule();
      }
      if (typeof this.props?.shouldReloadMainPage === "function") {
        this?.props?.shouldReloadMainPage(true);
      }
      toast.success(result.result?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "labortabcontainer",
      });
    });
  };

  onIsAllowPerformanceChange = (event) => {
    this.setState({ isAllowPerformance: event.target.checked }, () => {
      this.updateSalesToggle(false, true);
    });
  };

  onIsAllowPTOChange = (checked) => {
    if (!checked) {
      UserManagement.DeclineUserPTORequest(this.props?.uniqueno).then(
        (response: any) => {
          console.log(response);
        }
      );
    }
    this.setState({ isAllowPTO: checked }, () => {
      this.updateSalesToggle(true);
    });
  };

  openPTOAddEditModal = (ptoTypeEditRow = {}, isPTOTypeEdit = false) => {
    this.setState({ ptoTypeEditRow, isPTOTypeEdit }, () => {
      this.setState({ showPTOTypeModal: true });
    });
  };
  hidePTOTypeModal = (isReloadRequired = false) => {
    if (isReloadRequired) {
      this.props.getUserGeneralInfo(true);
    }
    this.setState({ showPTOTypeModal: false });
  };

  DownloadPRDocDocument = (row) => {
    UserManagement.DownloadPRDocDocument(
      row.scheduleId,
      row?.filename,
      ""
    ).then(() => {});
  };

  saveHotelPayrollDetails = () => {
    const { hotelWisePayrollBulkData, laborSettings } = this.state;

    let _hotelWisePayrollBulkData: any[] = hotelWisePayrollBulkData?.filter(
      (x) => x.isModified
    );

    if (
      _hotelWisePayrollBulkData.length !== hotelWisePayrollBulkData.length &&
      (laborSettings?.employeeType === "Salaried" ||
        laborSettings?.employeeType === "SalariedNonExempt") &&
      this.props?.userGeneralInfo?.employeeType !== "Salaried" &&
      this.props?.userGeneralInfo?.employeeType !== "SalariedNonExempt"
    ) {
      Utils.toastError(
        "Please enter total hours/week and annual salary of all entities in form",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "labortabcontainer",
        }
      );
      return;
    }

    let request: any[] = _.cloneDeep(
      hotelWisePayrollBulkData?.filter((x) => x.isModified)
    );
    if (request.length === 0) {
      return;
    }
    request.map((item: any) => {
      item.userUniqueNo = this.props?.uniqueno;
      item.employeeType = laborSettings?.employeeType;
      item.annualSal = item.annualSal
        ? +Utils.removecurrencyFormat(item.annualSal)
        : 0;
      item.totalHrs = item.totalHrs
        ? item.totalHrs?.toString()?.replace(":", ".")
        : "";
      item.status === "Active"
        ? (item.terDate = "")
        : (item.terDate = item.terDate);
    });

    this.setState({ isDataSubmitting: true });
    UserManagement.SaveHotelPayrollDetails(request)
      .then((result) => {
        if (result?.message === "Success") {
          this.props.getUserGeneralInfo(true);
          this.closeSalariedHoursEntryPanel(false);
          toast.success("Entity Wise Payroll Data Updated Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addDeduction",
          });
        } else {
          Utils.toastError(result?.result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addDeduction",
          });
        }
        this.setState({ isDataSubmitting: false, isFormValid: false });
      })
      .catch((error) => {
        this.setState({ isDataSubmitting: false, isFormValid: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addDeduction",
        });
      });
  };

  onFocusSSN = () => {
    // this.setState({showSSNValue:true})
  };

  autoFormatSSNOnBlur = () => {
    const laborSettings = _.cloneDeep(this.state.laborSettings);
    let ssn = laborSettings?.ssn?.toString();
    if (ssn?.length > 5 && !ssn?.includes("-")) {
      ssn =
        ssn.substr(0, 3) +
        "-" +
        ssn.substr(3, 2) +
        "-" +
        ssn.substr(5, ssn?.length - 4);
    }
    laborSettings.ssn = ssn;
    this.setState(
      {
        laborSettings,
        // showSSNValue:false
      },
      () => {
        this.validateLaborSettingFormSchema();
      }
    );
  };
  render() {
    const rowClasses = (row: any, rowIndex: any) => {
      if (this.state.isExpand === true && row.action === "Expended") {
        return "row-Expended";
      } else if (row.approveButtonSpinner) {
        return "p-none";
      } else if (this.state.isExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const rowClassesPerformanceReviewSection = (row: any, rowIndex: any) => {
      if (
        this.state.isPerformanceReviewSectionExpand === true &&
        row.action === "Expended"
      ) {
        return "row-Expended";
      } else if (row.approveButtonSpinner) {
        return "p-none";
      } else if (this.state.isPerformanceReviewSectionExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const rowHotelWideClasses = (row: any, rowIndex: any) => {
      if (this.state.isHotelWideExpand === true && row.action === "Expended") {
        return "row-Expended";
      } else if (row.approveButtonSpinner) {
        return "p-none";
      } else if (this.state.isHotelWideExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const acarowClasses = (row: any, rowIndex: any) => {
      if (this.state.isExpand === true && row.action === "Expended") {
        return "row-Expended";
      } else if (row.approveButtonSpinner) {
        return "p-none";
      } else if (this.state.isExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const ptoInnerColumns = [
      {
        dataField: "dateFormat",
        text: "Date",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <span className="adjustment-date">{cell}</span>
          </div>
        ),
      },
      {
        dataField: "adjPTO",
        text: "Hours",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <span className="adjustment-amount">{cell}</span>
          </div>
        ),
      },
      {
        dataField: "notes",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="d-flex">
            <Dropdown className="more-action notes-btn" alignRight>
              <Dropdown.Toggle
                className="btn-outline-primary  more"
                id="dropdown-more"
              >
                <div className="d-flex">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                    <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                  </svg>
                  <span>1</span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="pos-name">
                  <Dropdown.Item className="cross-btn" href="">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                        fill="#84888C"
                      />
                    </svg>
                  </Dropdown.Item>
                </div>
                <div className="notes-sec">
                  {/* <div className="notes-ttl">Edit History</div> */}
                  <div className="notes-list">
                    <div className="comment-list">
                      <div className="d-flex profile-details">
                        <div className="profile-img">
                          {row.profileImage ? (
                            <img
                              src={row.profileImage}
                              className={""}
                              width="40"
                              height="40"
                              alt={""}
                            />
                          ) : (
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="20" cy="20" r="20" fill="#E5F5FF" />
                              <path
                                d="M20.0013 20.2819C22.5548 20.2819 24.6422 18.1945 24.6422 15.6409C24.6422 13.0874 22.5548 11 20.0013 11C17.4477 11 15.3604 13.0874 15.3604 15.6409C15.3604 18.1945 17.4477 20.2819 20.0013 20.2819Z"
                                fill="#065A8C"
                              />
                              <path
                                d="M27.9658 23.9905C27.8442 23.6865 27.6821 23.4027 27.4997 23.1393C26.5674 21.7612 25.1286 20.8492 23.5073 20.6263C23.3047 20.606 23.0817 20.6465 22.9196 20.7681C22.0684 21.3964 21.0551 21.7206 20.0013 21.7206C18.9474 21.7206 17.9341 21.3964 17.0829 20.7681C16.9208 20.6465 16.6979 20.5857 16.4952 20.6263C14.8739 20.8492 13.4148 21.7612 12.5028 23.1393C12.3204 23.4027 12.1583 23.7067 12.0367 23.9905C11.9759 24.1121 11.9962 24.2539 12.057 24.3755C12.2191 24.6592 12.4217 24.943 12.6041 25.1862C12.8878 25.5713 13.1918 25.9157 13.5364 26.24C13.8201 26.5237 14.1444 26.7872 14.4686 27.0506C16.0696 28.2464 17.9949 28.8746 19.981 28.8746C21.9671 28.8746 23.8924 28.2463 25.4934 27.0506C25.8176 26.8075 26.1419 26.5237 26.4256 26.24C26.7499 25.9157 27.0741 25.5712 27.3579 25.1862C27.5605 24.9227 27.743 24.6592 27.9051 24.3755C28.0063 24.2539 28.0266 24.112 27.9658 23.9905Z"
                                fill="#065A8C"
                              />
                            </svg>
                          )}
                        </div>
                        <div>
                          <div className="main-ttl">{row?.loginUser}</div>
                          {/* <div className="sub-ttl">{row?.date}</div> */}
                        </div>
                      </div>
                      <ul>
                        <li>
                          <div className="d-flex">
                            <div className="detail-sec">
                              <div className="comments">
                                {row?.notes
                                  ?.toString()
                                  ?.replace(/\s{2,}/g, " ")
                                  .trim()}
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      },
    ];

   

    const expandRow = {
      renderer: (row: any) => (
        <div className="rowExpended" id="laborDayDetails">
          <div className="section-heading">Adjustments</div>
          <div className="adjustments-labor-paid-time-off-table">
            <BootstrapTable
              keyField="uniqueno"
              data={this.state.laborTimeOffSubData}
              columns={ptoInnerColumns}
              //expandRow={expandRow}
              rowClasses={rowClasses}
              hover
            />
          </div>
          {(this.props.isEditLaborandPayrollInfo ||
            this.props?.isNewUserAdded) && (
            <AddPTOAdjustment
              getPTOAdjustedDetails={this.getDetailsData}
              hireDate={this.props?.userGeneralInfo.hireDate}
              parentData={row}
            />
          )}
        </div>
      ),
      expanded: this.state.tableExpended,
      onExpand: this.handleOnExpand,
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const getStatus = (status) => {
      return +status > 0 ? (
        <span className="badge color-green">Active</span>
      ) : (
        <span className="badge color-grey">Inactive</span>
      );
    };

    const getStatusString = (status) => {
      return status === "Active" ? (
        <span className="badge color-green">Active</span>
      ) : (
        <span className="badge color-grey">Inactive</span>
      );
    };

    const getStatusReview = (status) => {
      if (status === "Complete") {
        return <span className="badge color-green">{status}</span>;
      } else if (status === "Incomplete") {
        return <span className="badge color-red">{status}</span>;
      } else if (status === "Overdue") {
        return <span className="badge color-orange">{status}</span>;
      }
    };

    const performanceReviewSectionColumns = [
      {
        dataField: "labelName",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="review-type-details d-flex flex-column">{cell}</div>
        ),
      },
    ];
    const handlePerformanceCollapse = (e,row,rowIndex, ExpandOnly=false)=>{
      const expandedItem=this.state.performanceTableExpended?.find(x => x == row.scheduleId)
      let isExpand = expandedItem ? false : this.state.isExpand
      if(ExpandOnly)
      {
        isExpand=true
      }
      this.handleOnPerformanceReviewExpand(row,isExpand,rowIndex,e)
    }

    const performanceTableColumnEvent = {
      onClick: (e, column, columnIndex, row, rowIndex) => handlePerformanceCollapse(e,row,rowIndex),
    };

    const performanceReviewMainColumns = [
      {
        dataField: "reviewType",
        text: "",
        events: performanceTableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="review-type-details d-flex flex-column">
            <span className="review-frequency">{row?.frequencyName}</span>
            <span className="review-type">{row?.reviewType}</span>
          </div>
        ),
      },
      {
        dataField: "status",
        text: "",
        events: performanceTableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>{getStatusReview(row?.status)}</div>
        ),
      },
      {
        dataField: "nextDueDate",
        text: "",
        events: performanceTableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="paid-time-off-balance-details due-date-details d-flex flex-column">
            <span className="paid-time-off-balance due-date">
              {row?.nextDueDate}
            </span>
            <span className="paid-time-off-balance-label due-date-label">
              Due Date
            </span>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        events: performanceTableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <>
            {(this.props.isEditLaborandPayrollInfo ||
              this.props?.isNewUserAdded) && (
              <Button
                type="button"
                onClick={(e) =>{
                   
                    this.showHidePerformanceReviewApprovalModal(false, row)
                  }
                }
                className="btn-outline-primary btn btn-primary"
              >
                {row?.status !== "Complete" ? "Start Review" : "Re-Open"}
              </Button>
            )}
          </>
        ),
      },

      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Dropdown
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}

            className="more-action"
            alignRight
          >
            <Dropdown.Toggle
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
              disabled={
                !row?.filename &&
                !(
                  row?.status !== "Complete" &&
                  row.reviewType !== "Annual Review" &&
                  this.props.isEditLaborandPayrollInfo
                )
              }
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {row?.status !== "Complete" &&
                row.reviewType !== "Annual Review" &&
                this.props.isEditLaborandPayrollInfo && (
                  <Dropdown.Item
                    key={"Delete"}
                    onClick={() => this.getUserPerformanceReviewIsDeleted(row)}
                    eventKey={"Delete"}
                  >
                    Delete
                  </Dropdown.Item>
                )}

              {row?.filename && (
                <Dropdown.Item
                  key={"Download"}
                  onClick={() => this.DownloadPRDocDocument(row)}
                  eventKey={"Download"}
                >
                  Download
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];

    const acaexpandRow = {
      renderer: (row: any) => (
        <div className="rowExpended" id="laborDayDetails">
          Some text
          <div className="laborDayApprovalTable cuslaborDayApprovalTable"></div>
        </div>
      ),
      expanded: this.state.tableExpended,
      onExpand: this.handleOnExpand,
      showExpandColumn: true,
      expandByColumnOnly: false,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    

    const expandRowPerformanceReviewSection = {
      renderer: (row: any) => (
        <>
          {row.data?.length > 0 && (
            <div className="rowExpended" id="laborDayDetails">
              <BootstrapTable
                keyField="scheduleId"
                data={row?.data || []}
                columns={performanceReviewMainColumns}
                expandRow={acaexpandRowPerformanceReview}
                rowClasses={rowClasses}
                hover
              />
            </div>
          )}
        </>
      ),
      expanded: this.state.performanceSectionTableExpended,
      onExpand: this.handleOnPerformanceReviewSectionExpand,
      showExpandColumn: true,
      expandByColumnOnly: false,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    

    const acaexpandRowPerformanceReview = {
      renderer: (row: any) => (
        <>
          {this.state?.performanceReviewComments?.length > 0 && (
            <div className="rowExpended" id="laborDayDetails">
              <div className="section-heading">Comments</div>
              <div className="comments-container">
                {(this.state?.performanceReviewComments || [])?.map((item) => {
                  return (
                    <>
                      <div className="comments-thread">
                        <div className="comments-datetime">
                          Reviewed By : {item?.reviewedBy},{" "}
                          {item?.date
                            ? moment(item?.date).format("MM/DD/YY hh:mm:ss ")
                            : ""}
                        </div>
                        <div className="comments-txt">
                          {item?.comments
                            ?.toString()
                            ?.replace(/\s{2,}/g, " ")
                            .trim()}
                        </div>
                      </div>{" "}
                    </>
                  );
                })}
              </div>
              <div className="laborDayApprovalTable cuslaborDayApprovalTable"></div>
            </div>
          )}
        </>
      ),
      expanded: this.state.performanceTableExpended,
      onExpand: this.handleOnPerformanceReviewExpand,
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const acacolumns = [
      {
        dataField: "acaType",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="aca-tracking-details d-flex flex-column">
            <span className="aca-coverage">{cell}</span>
            <>
              {" "}
              {row.startDate && (
                <span className="effective-date">
                  Effective {row.startDate}
                </span>
              )}
            </>
          </div>
        ),
      },
      {
        dataField: "statusCode",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <>
            {" "}
            {cell && (
              <div className="aca-tracking-code-details d-flex flex-column">
                <span className="aca-tracking-code">{cell}</span>
                <span className="aca-tracking-code-label">Code</span>
              </div>
            )}
          </>
        ),
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Dropdown
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}
            className="more-action disabled"
            alignRight
          >
            <Dropdown.Toggle
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
              disabled={
                !(
                  this.props.isEditLaborandPayrollInfo ||
                  this.props?.isNewUserAdded
                )
              }
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.state.acaActions.map((item) => (
                <Dropdown.Item
                  key={item}
                  disabled={
                    !(
                      this.props.isEditLaborandPayrollInfo ||
                      this.props?.isNewUserAdded
                    )
                  }
                  eventKey={item}
                  onClick={() => this.handleAcaActions(item, row)}
                >
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];

     const handlePTOTableCollapse  = (e,row,rowIndex, ExpandOnly=false)=>{

      const expandedItem=this.state.tableExpended?.find(x => x == row.ptoUniqueNo)
      let isExpand = expandedItem ? false : this.state.isExpand

      if(ExpandOnly)
      {
        isExpand=true
      }

      this.handleOnExpand(row,isExpand,rowIndex,e)
    }

    const ptoTableColumnEvent = {
      onClick: (e, column, columnIndex, row, rowIndex) => handlePTOTableCollapse(e,row,rowIndex),
    };
    const ptoMainColumns = [
      {
        dataField: "ptoName",
        text: "",
        events: ptoTableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="paid-time-off-details d-flex flex-column">
            <span className="paid-time-off-type">{cell}</span>
            <span className="effective-date">Effective {row.ptoStartDate}</span>
          </div>
        ),
      },
      {
        dataField: "status",
        text: "",
        events: ptoTableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>{getStatus(row.status)}</div>
        ),
      },
      {
        dataField: "underBudgetDept",
        text: "",
        events: ptoTableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="paid-time-off-balance-details d-flex flex-column">
            <span className="paid-time-off-balance">
              {row.ptoStartingBalance}
            </span>
            <span className="paid-time-off-balance-label">Balance</span>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          const confirmModalContent = [
            {
              title: "Delete Mapping Rule",
              desc: "Are you sure you want to delete this mapping rule?",
              cancleAction: "Cancel",
              ActionTrue: "Delete",
            },
          ];
          return (
            <Dropdown
              // onClick={(e) => {
              //   e.stopPropagation();
              // }}
              className="more-action"
              alignRight
            >
              <Dropdown.Toggle
                className="btn-outline-primary btn btn-primary btn-ghost more"
                id="dropdown-more"
                disabled={
                  !(
                    this.props.isEditLaborandPayrollInfo ||
                    this.props?.isNewUserAdded
                  ) || row?.laborPTOCalculationsStatus === "Inactive"
                }
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                  <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                  <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <ConfirmationModal
                  confirmModalContent={confirmModalContent}
                  ishandleDeletePosition={this.ishandleDeletePosition.bind(
                    this,
                    row,
                    "Delete"
                  )}
                /> */}

                <Dropdown.Item
                  key={"Edit"}
                  onClick={(e) => {
                    handlePTOTableCollapse(e,row,rowIndex,true)
                    this.openPTOAddEditModal(row, true)
                  }}
                  eventKey={"Edit"}
                  disabled={
                    !(
                      this.props.isEditLaborandPayrollInfo ||
                      this.props?.isNewUserAdded
                    ) || row?.laborPTOCalculationsStatus === "Inactive"
                  }
                >
                  Edit
                </Dropdown.Item>

                {/* <Dropdown.Item
                  key={row?.status}
                  onClick={() => this.updateStatus(row)}
                  eventKey={"status"}
                  disabled={!this.props.isEditLaborandPayrollInfo}
                >
                  {+row?.status === 1 ? "Inactivate" : "Activate"}
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
      //{
      //   dataField: "",
      //   text: "",
      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     (
      //     const confirmModalContent = [
      //       {
      //         title: "Delete Mapping Rule",
      //         desc: "Are you sure you want to delete this mapping rule?",
      //         cancleAction: "Cancel",
      //         ActionTrue: "Delete",
      //       },
      //     ];
      //     return (<Dropdown
      //       onClick={(e) => {
      //         e.stopPropagation();
      //       }}
      //       className="more-action"
      //       alignRight
      //     >
      //       <Dropdown.Toggle
      //         className="btn-outline-primary btn btn-primary btn-ghost more"
      //         id="dropdown-more"
      //       >
      //         <svg
      //           width="20"
      //           height="20"
      //           viewBox="0 0 20 20"
      //           fill="none"
      //           xmlns="http://www.w3.org/2000/svg"
      //         >
      //           <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
      //           <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
      //           <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
      //         </svg>
      //       </Dropdown.Toggle>
      //       <Dropdown.Menu>
      //         <Dropdown.Item
      //           key={row?.status}
      //           onClick={() => this.updateStatus(row)}
      //           eventKey={"status"}
      //           disabled={!this.props.isEditLaborandPayrollInfo}
      //         >
      //           {+row?.status === 1 ? "Inactivate" : "Activate"}
      //         </Dropdown.Item>
      //       </Dropdown.Menu>
      //     </Dropdown>
      //   )},
      // },
    ];

    const {
      laborSettings,
      employeeTypes,
      isLaborSettingNotChanged,
      userHotels,
      showSalariedHoursEntryPanel,
      acaTableRow,
      laborSettingsErrors,
      showACAUpload,
      showACACodeEdit,
      showHideDOBControl,
      inputPosition,
      isFormValid,
      isDataSubmitting,
      showHideExcludeFromPayroll,
      performanceReviews,
      addPerformanceReview,
      isAllowPTO,
      isAllowACATracking,
      spacialRates,
      isAllowPerformance,
      showHotelWiseAddEditModal,
      showSpecialRateAddEditModal,
      isHotelWisePayrollLoaded,
      isSpecialRateLoaded,
      specialRateRowEdit,
      hotelWideTableExpended,
      isHotelWideExpand,
      hotelWideTableData,
      hotelWisePayrollRowEdit,
      addPerformanceReviewApproval,
      showPTOTypeModal,
      ptoTypeEditRow,
      isPTOTypeEdit,
      hotelWisePayrollBulkData,
      showDuplicateSSNModal,
      duplicateSSNTableData,
      allowanceTableExpanded,
      isAllowanceExpand,
      showAllowanceAddEditModal,
      AllowancePayrollRowEdit,
      isAllowancePayrollLoaded,
      allowanceTableData,
      ispayrollIntegrated,
    } = this.state;
    const { username, userGeneralInfo } = this.props;

    const duplicateSSNTableColumns: any[] = [
      {
        dataField: "employeeName",
        text: "Employee",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="employee-name">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "username",
        text: "Username",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="user-name">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "userId",
        text: "User ID",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="user-id">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "rolePermission",
        text: "Role Permission",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="role-permission">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "ehid",
        text: "EHID",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="ehid">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "status",
        text: "Status",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="status">{cell}</div>
        ),
      },
    ];

    const hotelWideSettingProps: IHotelWideSettingProps = {
      hotelWideTableData: hotelWideTableData,
      showSpecialRateAddEditModal,
      showSpecialRateAddEditModalForm: this.showSpecialRateAddEditModalForm,
      userGeneralInfo,
      deletePayrollSpecialRate: this.deletePayrollSpecialRate,
      spacialRates,
      isSpecialRateLoaded,
      hideSpecialRateAddEditModalForm: this.hideSpecialRateAddEditModalForm,
      hotelWideTableExpended,
      specialRateRowEdit,
      handleOnHotelWideExpand: this.handleOnHotelWideExpand,
      isHotelWideExpand,
      showHotelWiseAddEditModal,
      showHotelWiseAddEditModalForm: this.showHotelWiseAddEditModalForm,
      hideHotelWiseAddEditModalForm: this.hideHotelWiseAddEditModalForm,
      isHotelWisePayrollLoaded,
      hotelWisePayrollRowEdit,
      isPayrollTab: false,
      userUniqueNo: this.props?.uniqueno,
      clientSetupData: this.props?.clientSetupData,
      hasHWPSetting: true,
      hasViewSensitiveDataPermission:
        this.props?.hasViewSensitiveDataPermission,
      isFromPayroll: false,
      isEditLaborandPayrollInfo:
        this.props.isEditLaborandPayrollInfo || this.props?.isNewUserAdded,
    };

    const payrollAllowancesProps: IPayrollAllowancesProps = {
      allowanceTableData: allowanceTableData,
      hotelWideTableData: hotelWideTableData,
      userGeneralInfo,
      allowanceTableExpanded,
      isAllowanceExpand,
      showAllowanceAddEditModal,
      showAllowanceAddEditModalForm: this.showAllowanceAddEditModalForm,
      hideAllowanceAddEditModalForm: this.hideAllowanceAddEditModalForm,
      AllowancePayrollRowEdit,
      isAllowancePayrollLoaded,
      isPayrollTab: false,
      userUniqueNo: this.props?.uniqueno,
      clientSetupData: this.props?.clientSetupData,
      hasHWPSetting: true,
      hasViewSensitiveDataPermission:
        this.props?.hasViewSensitiveDataPermission,
      isFromPayroll: false,
      isEditLaborandPayrollInfo:
        this.props.isEditLaborandPayrollInfo || this.props?.isNewUserAdded,
      handleOnAllowanceExpand: this.handleOnAllowanceExpand,
    };
    return (
      <>
        <Modal
          className="set-salaried-hour-modal"
          show={showSalariedHoursEntryPanel}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => this.closeSalariedHoursEntryPanel(true)}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Set Salaried Hours/Week</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-section d-flex flex-row">
              <div className="set-salaried-hours-week-table">
                <div className="table-header d-flex">
                  <div className="ehid">EHID</div>
                  <div className="total-hours">Total Hours/Week</div>
                  <div className="salary">Annual Salary</div>
                  <div className="hourly-rate">Regular Rate</div>
                </div>
                <div className="table-row d-flex flex-column align-items-center">
                  {(hotelWisePayrollBulkData || [])?.map((item, index) => (
                    <Form.Group>
                      <div className="time-entry d-flex align-items-center">
                        <div className="ehid">
                          <label>{item?.lettercode}</label>
                        </div>
                        <div
                          className={`total-hours ${
                            +item?.totalHrs?.toString()?.replace(":", ".") > 0
                              ? ""
                              : "validation-error"
                          }`}
                        >
                          <input
                            onBlur={(event: any) =>
                              this.onHourEntryBlur(event.target.value, index)
                            }
                            className="form-control"
                            placeholder="HH:MM"
                            onChange={(event: any) =>
                              this.updateUserHotelsData(
                                event.target.value,
                                "totalHrs",
                                index
                              )
                            }
                            onKeyPress={(event) => {
                              Utils.OnlyNumbersWithColon(event);
                            }}
                            maxLength={5}
                            type="string"
                            value={item?.totalHrs}
                          />
                        </div>
                        <div
                          className={`salary ${
                            +(item?.annualSal
                              ? Utils.removecurrencyFormat(item?.annualSal)
                              : 0) > 0
                              ? ""
                              : "validation-error"
                          }`}
                        >
                          {+(item?.annualSal
                            ? Utils.removecurrencyFormat(item?.annualSal)
                            : 0) > 0 && (
                            <ReactTooltip
                              id={"txtAnnualSal" + index}
                              effect="solid"
                              place="bottom"
                            >
                              {item.annualSal}
                            </ReactTooltip>
                          )}
                          <input
                            className="form-control"
                            id="txtAnnualSal"
                            data-tip
                            data-for={"txtAnnualSal" + index}
                            name="annualSal"
                            onBlur={(e) =>
                              this.handleAnnualSalaryFormat(e, index)
                            }
                            onFocus={(e) => this.onAmountFocus(e, index)}
                            pattern="[+-]?\d+(?:[.,]\d+)?"
                            autoComplete="txtAnnualSal"
                            maxLength={20}
                            placeholder="$0.00"
                            value={item.annualSal}
                            title=""
                            onChange={(event: any) =>
                              this.updateUserHotelsData(
                                event.target.value,
                                "annualSal",
                                index
                              )
                            }
                          />
                        </div>
                        <div className="hourly-rate">
                          <input
                            type="text"
                            min="0"
                            className="form-control"
                            id="txtAnnualSal"
                            name="annualSal"
                            autoComplete="txtAnnualSal"
                            disabled={true}
                            value={item.regularRate}
                            onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  ))}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="import-forecast-button btn-ghost"
              onClick={() => this.closeSalariedHoursEntryPanel(true)}
            >
              Cancel
            </Button>
            <Button
              disabled={
                hotelWisePayrollBulkData?.length !==
                  hotelWisePayrollBulkData?.filter((x) => x.isModified)
                    ?.length || isDataSubmitting
              }
              type="submit"
              className="import-forecast-button"
              onClick={() => this.saveHotelPayrollDetails()}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="labor-tab">
          <>
            <div className="body-section">
              <div className="heading">Labor Settings</div>

              <div className="form-section d-flex flex-column">
                <Form.Group
                  onClick={() => this.validationOnBlur(1)}
                  controlId="date-selection"
                  className={
                    laborSettingsErrors?.employeeType && inputPosition >= 1
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                >
                  <Form.Label>
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          laborSettingsErrors?.employeeType ? "pips" : ""
                        }
                      >
                        <div className="pip light-red"></div>
                      </div>
                      <div>Employee Type</div>
                    </div>
                  </Form.Label>
                  <div className="validate-input d-flex flex-column">
                    {employeeTypes?.length > 0 && (
                      <DropDownList
                        placeHolder={"Search Employee Type"}
                        defaultValue={laborSettings.employeeType?.toString()}
                        data={employeeTypes}
                        isSearchRequired={false}
                        disabled={
                          !(
                            this.props.isEditLaborandPayrollInfo ||
                            this.props?.isNewUserAdded
                          ) || ispayrollIntegrated
                        }
                        label={"name"}
                        value={"id"}
                        onDropDownChange={(item) => {
                          if (!item) {
                            return;
                          }
                          this.onLaborSettingsFieldChange(
                            this.buildCustomEventObject(
                              "employeeType",
                              item.id
                            ),
                            1
                          );
                        }}
                        selectedItem={
                          [
                            ...[{ id: "", name: "Select Employee Type" }],
                            ...employeeTypes,
                          ].find(
                            (r) => r.name === laborSettings.employeeType
                          ) || {
                            id: "",
                            name: "Select Employee Type",
                          }
                        }
                      />
                    )}
                    {this.getFieldError("employeeType", 1)}
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="date-selection"
                  className="d-flex flex-row"
                >
                  <Form.Label>Average Hours Worked</Form.Label>
                  <input
                    type="number"
                    onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                    min="0"
                    className="form-control"
                    id="averageHoursWorked"
                    placeholder="0.00"
                    autoComplete="averageHoursWorked"
                    name="averageHoursWorked"
                    max={40}
                    disabled={
                      !(
                        this.props.isEditLaborandPayrollInfo ||
                        this.props?.isNewUserAdded
                      )
                    }
                    value={laborSettings?.averageHoursWorked}
                    onChange={(e: any) => this.onLaborSettingsFieldChange(e, 3)}
                  />
                  {this.getFieldError("averageHoursWorked", 3)}
                </Form.Group>

                {laborSettings?.employeeType !== "ExcludefromPayroll" &&
                  laborSettings?.employeeType && (
                    <Form.Group
                      onBlur={() => this.validationOnBlur(4)}
                      controlId="date-selection"
                      className={
                        laborSettingsErrors?.dOB && inputPosition >= 4
                          ? "validation-error d-flex flex-row date-picker"
                          : "d-flex flex-row date-picker"
                      }
                    >
                      <Form.Label>
                        <div className="d-flex align-items-center">
                          <div
                            className={
                              laborSettingsErrors?.dOB &&
                              this.state?.hasViewSensitiveDataPermission
                                ? "pips"
                                : ""
                            }
                          >
                            <div className="pip light-red"></div>
                          </div>
                          <div>Date of Birth</div>
                        </div>
                      </Form.Label>

                      <div className="validate-input d-flex flex-column">
                        <div className="calender-container">
                          <div
                            className={
                              this.state.calenderShow
                                ? "calender-display open"
                                : "calender-display"
                            }
                          >
                            <input
                              type="text"
                              onClick={this.calenderShow}
                              onFocus={this.calenderShow}
                              disabled={
                                !(
                                  this.props.isEditLaborandPayrollInfo ||
                                  this.props?.isNewUserAdded
                                )
                                // || ispayrollIntegrated
                              }
                              placeholder="MM/DD/YYYY"
                              maxLength={10}
                              onInput={(e) => {
                                let value = e.currentTarget.value;

                                value = value
                                  ?.toString()
                                  ?.replace(/[^0-9\/]+/gi, "");
                                this.onLaborSettingsFieldChange(
                                  this.buildCustomEventObject("dOB", value),
                                  4
                                );
                              }}
                              onBlur={(e) => {
                                let value = e.currentTarget.value;

                                value = value
                                  ?.toString()
                                  ?.replace(/[^0-9\/]+/gi, "");
                                this.onLaborSettingsFieldChange(
                                  this.buildCustomEventObject("dOB", value),
                                  4,
                                  true
                                );
                              }}
                              value={laborSettings.dOB}
                              className="form-control single-date-pick"
                              name="dob"
                              autoComplete="dob"
                            />
                            <svg
                              onClick={() =>
                                this.props.isEditLaborandPayrollInfo ||
                                this.props?.isNewUserAdded
                                  ? this.calenderShow()
                                  : {}
                              }
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                              <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                            </svg>
                          </div>
                          {this.state.calenderShow && (
                            <div className="calender">
                              <OutsideClickHandler
                                onOutsideClick={this.calenderHide}
                              >
                                <Calendar
                                  calendarType={"US"}
                                  disabled={
                                    !(
                                      this.props.isEditLaborandPayrollInfo ||
                                      this.props?.isNewUserAdded
                                    )
                                  }
                                  onChange={(date) => {
                                    const value =
                                      moment(date).format("MM/DD/YYYY");
                                    this.onLaborSettingsFieldChange(
                                      this.buildCustomEventObject("dOB", value),
                                      4
                                    );
                                    this.calenderHide();
                                  }}
                                  value={
                                    laborSettings.dOB
                                      ? moment(laborSettings.dOB)?.format(
                                          "MM/DD/YY"
                                        ) === "Invalid date"
                                        ? new Date()
                                        : new Date(laborSettings.dOB)
                                      : new Date()
                                  }
                                  minDate={
                                    this.props?.hireDate
                                      ? new Date(
                                          moment(this.props?.hireDate).format(
                                            "MM/DD/YYYY"
                                          )
                                        )
                                      : undefined
                                  }
                                  maxDate={new Date()}
                                />
                              </OutsideClickHandler>
                            </div>
                          )}
                        </div>

                        {this.getFieldError("dOB", 4)}
                      </div>
                    </Form.Group>
                  )}

                <Form.Group
                  controlId="ssn-selection"
                  className={
                    laborSettingsErrors?.ssn && inputPosition >= 5
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                  onBlur={() => this.validationOnBlur(5)}
                >
                  <Form.Label>
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          laborSettingsErrors?.ssn &&
                          this.state?.hasViewSensitiveDataPermission
                            ? "pips"
                            : ""
                        }
                      >
                        <div className="pip light-red"></div>
                      </div>
                      <div>SSN</div>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="test">
                            SSN is required for clients who subscribe to
                            Inn-Flow Payroll or Payroll Integrations.
                          </Tooltip>
                        }
                      >
                        <div style={{ paddingBottom: "2px" }}>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            version="1.1"
                            viewBox="0 0 16 16"
                            height="1em"
                            width="1.5em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                            <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                            <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                          </svg>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <input
                      onFocus={() => this.onFocusSSN()}
                      onBlur={() => this.autoFormatSSNOnBlur()}
                      type="text"
                      className={`form-control`}
                      id="txtSSN"
                      name="ssn"
                      maxLength={11}
                      disabled={
                        !(
                          this.props.isEditLaborandPayrollInfo ||
                          this.props?.isNewUserAdded
                        )
                      }
                      onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithDash}
                      autoComplete="ssn"
                      // disabled={
                      //   ((!hasAddPermission && !hasEditPermission) ||
                      //     !hasViewSensitiveDataPermission) && !this.props?.isNewUserAdded
                      // }
                      value={this.state.laborSettings?.ssn}
                      onChange={(e: any) =>
                        this.onLaborSettingsFieldChange(e, 5)
                      }
                    />
                    {this.getFieldError("ssn", 5)}
                  </div>
                </Form.Group>
                {laborSettings.employeeType === "Contractor" && (
                  <>
                    <Form.Group
                      onClick={() => this.validationOnBlur(6)}
                      controlId="date-selection"
                      className={
                        laborSettingsErrors?.paidByVendor && inputPosition >= 6
                          ? "validation-error d-flex flex-row"
                          : "d-flex flex-row"
                      }
                    >
                      <Form.Label>
                        <div className="d-flex align-items-center">
                          <div
                            className={
                              laborSettingsErrors?.paidByVendor ? "pips" : ""
                            }
                          >
                            <div className="pip light-red"></div>
                          </div>
                          <div>Payment</div>
                        </div>
                      </Form.Label>
                      <div className="validate-input d-flex flex-column">
                        {/* {employeeTypes?.length > 0 && ( */}
                        <DropDownList
                          placeHolder={"Search Payment Type"}
                          defaultValue={laborSettings.paidByVendor}
                          data={this.paymentTypes}
                          isSearchRequired={false}
                          disabled={
                            !(
                              this.props.isEditLaborandPayrollInfo ||
                              this.props?.isNewUserAdded
                            )
                          }
                          label={"label"}
                          value={"value"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onLaborSettingsFieldChange(
                              this.buildCustomEventObject(
                                "paidByVendor",
                                item.value
                              ),
                              6
                            );
                          }}
                          selectedItem={
                            [
                              ...[{ value: "", label: "Select Payment" }],
                              ...this.paymentTypes,
                            ].find(
                              (r) => r.value === laborSettings?.paidByVendor
                            ) || {
                              value: "",
                              label: "Select Payment",
                            }
                          }
                        />
                        {/* )} */}
                        {this.getFieldError("paidByVendor", 6)}
                      </div>
                    </Form.Group>
                    <>
                      {laborSettings?.paidByVendor === 2 && (
                        <Form.Group
                          onClick={() => this.validationOnBlur(7)}
                          className={
                            laborSettingsErrors?.vendorId && inputPosition >= 7
                              ? "validation-error d-flex flex-row"
                              : "d-flex flex-row"
                          }
                          controlId="vendorName"
                        >
                          <Form.Label>
                            <div className="d-flex align-items-center">
                              <div
                                className={
                                  laborSettingsErrors?.vendorId ? "pips" : ""
                                }
                              >
                                <div className="pip light-red"></div>
                              </div>
                              <div>Vendor/Agency</div>
                            </div>
                          </Form.Label>
                          <div className="pos-unset">
                            <VendorSearchUM
                              //  ref={this.vendorSearch}
                              // updateVendorList={this.updateVendorList}
                              // HID={this.state.hotelValue}
                              vendorId={laborSettings?.vendorId}
                              handleVendorChange={(item) => {
                                if (!item) {
                                  return;
                                }
                                this.onLaborSettingsFieldChange(
                                  this.buildCustomEventObject(
                                    "vendorId",
                                    item?.vid
                                  ),
                                  7
                                );
                              }}
                              disabled={
                                !(
                                  this.props.isEditLaborandPayrollInfo ||
                                  this.props?.isNewUserAdded
                                )
                              }

                              //  pageName={this.pageName}
                              // addContractPermission={this.addContractPermission}
                            />
                            {this.getFieldError("vendorId", 7)}
                          </div>
                        </Form.Group>
                      )}
                    </>
                    <Form.Group>
                      <div className="switch-heading d-flex align-items-center">
                        <Form.Check
                          type="switch"
                          id="allowContactorOverTime"
                          label=""
                          // disabled={!(this.props.isEditLaborandPayrollInfo || this.props?.isNewUserAdded)}
                          name="chkIsAllowPTO"
                          disabled={
                            !(
                              this.props.isEditLaborandPayrollInfo ||
                              this.props?.isNewUserAdded
                            )
                          }
                          checked={laborSettings?.allowContactorOverTime}
                          onChange={(event: any) => {
                            this.onLaborSettingsFieldChange(
                              this.buildCustomEventObject(
                                "allowContactorOverTime",
                                event.target.checked
                              ),
                              6
                            );
                          }}
                        />

                        <div className="heading mod-for-pips-icon">
                          Allow Overtime
                        </div>
                      </div>
                    </Form.Group>
                  </>
                )}
              </div>
            </div>

            <>
              {this.props?.userGeneralInfo?.employeeType &&
                Object.keys(userGeneralInfo).length > 1 &&
                laborSettings.employeeType &&
                this.props?.userGeneralInfo?.hids?.filter(
                  (x) => x?.loginUserHasAccess
                )?.length > 0 && (
                  <>
                    <HotelWideSettings {...hotelWideSettingProps} />
                  </>
                )}
            </>
            <>
              {hotelWideTableData &&
                hotelWideTableData.length > 0 &&
                hotelWideTableData.filter((hotel) => hotel.status === "Active")
                  .length > 0 &&
                (laborSettings?.employeeType == "HourlyWage" ||
                  laborSettings?.employeeType == "Salaried" ||
                  laborSettings?.employeeType == "SalariedNonExempt") &&
                this.props?.userGeneralInfo?.employeeType &&
                Object.keys(userGeneralInfo).length > 1 &&
                laborSettings.employeeType &&
                this.props?.userGeneralInfo?.hids?.filter(
                  (x) => x?.loginUserHasAccess
                )?.length > 0 && (
                  <>
                    <PayrollAllowances {...payrollAllowancesProps} />
                  </>
                )}
            </>

            {this.props?.userGeneralInfo?.hasViewLaborSettingAccess &&
              this.checkHasTabAccess("labor management") &&
              laborSettings?.employeeType !== "ExcludefromPayroll" &&
              laborSettings.employeeType !== "Contractor" &&
              laborSettings?.employeeType && (
                <>
                  <div className="separator-line"></div>

                  <div className="body-section">
                    <div className="switch-heading d-flex align-items-center">
                      <Form.Check
                        type="switch"
                        id="isAllowPTO"
                        label=""
                        disabled={
                          !(
                            this.props.isEditLaborandPayrollInfo ||
                            this.props?.isNewUserAdded
                          )
                        }
                        name="chkIsAllowPTO"
                        checked={isAllowPTO}
                        onChange={(event: any) => {
                          this.confirmUpdateStatus(event);
                        }}
                      />
                      <>
                        {this.state.laborTimeOffData.filter(
                          (x) => +x?.status > 0
                        ).length === 0 &&
                          isAllowPTO && (
                            <div className="pips">
                              <div className="pip light-red"></div>
                            </div>
                          )}
                      </>

                      <div className="heading mod-for-pips-icon">
                        Paid Time Off{" "}
                      </div>
                    </div>

                    {/* <OutsideClickHandler
                  onOutsideClick={(e) => {
                    //this.onOutsideClick(e);
                  }}
                > */}
                    {isAllowPTO && (
                      <div className="labor-paid-time-off-table">
                        <BootstrapTable
                          keyField="ptoUniqueNo"
                          data={this.state.laborTimeOffData}
                          columns={ptoMainColumns}
                          expandRow={expandRow}
                          rowClasses={rowClasses}
                          hover
                        />
                      </div>
                    )}
                    {/* </OutsideClickHandler> */}
                  </div>
                  {laborSettings?.employeeType !== "ExcludefromPayroll" &&
                    isAllowPTO &&
                    (this.props.isEditLaborandPayrollInfo ||
                      this.props?.isNewUserAdded) &&
                    this.state.isPTOUserListLoaded && (
                      <>
                        {this.state?.ptoNames?.length > 0 && (
                          <div className="add-pto-type-wrapper">
                            <Button
                              onClick={(e: any) => this.openPTOAddEditModal()}
                              className="btn-ghost"
                            >
                              Add PTO Type
                            </Button>
                          </div>
                        )}
                        {showPTOTypeModal && (
                          <AddPTOType
                            getPTOUserList={this.getPTOUserList}
                            userName={username}
                            isPTOTypeEdit={isPTOTypeEdit}
                            showPTOTypeModal={showPTOTypeModal}
                            ptoTypeEditRow={ptoTypeEditRow}
                            hidePTOTypeModal={this.hidePTOTypeModal}
                            hireDate={this.props?.userGeneralInfo.hireDate}
                            laborTimeOffData={this.state.laborTimeOffData}
                            isNewUserAdded={this.props?.isNewUserAdded}
                          />
                        )}
                      </>
                    )}
                </>
              )}
            {/* {this.props?.userGeneralInfo?.hasViewLaborSettingAccess &&
              this.checkHasTabAccess("labor management") &&
              laborSettings?.employeeType !== "ExcludefromPayroll" &&
              laborSettings?.employeeType && ( */}
            <>
              {(laborSettings?.employeeType == "HourlyWage" ||
                laborSettings?.employeeType == "Salaried" ||
                laborSettings?.employeeType == "SalariedNonExempt" ||
                laborSettings?.employeeType == "Contractor") && (
                <>
                  <div className="separator-line"></div>
                  <div className="body-section">
                    <div className="switch-heading d-flex align-items-center">
                      <Form.Check
                        type="switch"
                        id="isAllowPerformance"
                        label=""
                        disabled={
                          !(
                            this.props.isEditLaborandPayrollInfo ||
                            this.props?.isNewUserAdded
                          )
                        }
                        name="chkIsAllowPerformance"
                        checked={isAllowPerformance}
                        onChange={(event: any) => {
                          this.onIsAllowPerformanceChange(event);
                        }}
                      />

                      <div className="heading mod-for-pips-icon">
                        Performance Reviews{" "}
                      </div>
                    </div>
                    {isAllowPerformance && performanceReviews?.length > 0 && (
                      <div className="labor-performance-reviews-table">
                        <BootstrapTable
                          keyField="id"
                          data={performanceReviews}
                          columns={performanceReviewSectionColumns}
                          expandRow={expandRowPerformanceReviewSection}
                          rowClasses={rowClassesPerformanceReviewSection}
                          hover
                        />
                      </div>
                    )}
                    {/* </OutsideClickHandler> */}
                  </div>
                  {isAllowPerformance &&
                    (this.props.isEditLaborandPayrollInfo ||
                      this.props?.isNewUserAdded) &&
                    userGeneralInfo.hids.length > 0 && (
                      <div className="add-review-schedule-wrapper">
                        <Button
                          className="btn-ghost"
                          onClick={this.addNewPerformanceReview}
                        >
                          Add Review
                        </Button>
                      </div>
                    )}
                  {this.state.addPerformanceReview && (
                    <AddPerformanceReview
                      userHotels={userGeneralInfo.hids?.filter(
                        (x) =>
                          x?.status === "Active" &&
                          x?.loginUserHasAccess &&
                          hotelWideTableData
                            ?.filter((l) => l?.status === "Active")
                            ?.map((l) => l?.hid)
                            ?.includes(x?.hotelID)
                      )}
                      addPerformanceReview={addPerformanceReview}
                      hidePerformanceReviewModal={
                        this.hidePerformanceReviewModal
                      }
                      hireDate={
                        userGeneralInfo?.hireDate
                          ? moment(userGeneralInfo?.hireDate).format("MM/DD/YY")
                          : ""
                      }
                      getUserReviewSchedule={this.getUserReviewSchedule}
                      userName={userGeneralInfo?.userName}
                      userUniqueNo={this.props?.uniqueno}
                    />
                  )}
                  {this.state.addPerformanceReviewApproval && (
                    <AddPerformanceReviewApproval
                      performanceReviewAprrovalRow={
                        this.state.performanceReviewAprrovalRow
                      }
                      addPerformanceReviewApproval={
                        addPerformanceReviewApproval
                      }
                      hidePerformanceReviewApprovalModal={
                        this.showHidePerformanceReviewApprovalModal
                      }
                    />
                  )}
                </>
              )}
              {/* )} */}
            </>
            {/* )} */}
          </>

          <div className="separator-line"></div>
          <div className="body-section">
            {showACACodeEdit && (
              <ACACodeEdit
                userUniqueNo={this.props?.uniqueno}
                showACACodeEdit={showACACodeEdit}
                parentData={acaTableRow}
                handleCloseACACodeEdit={this.handleCloseACACodeEdit}
              />
            )}
            {showACAUpload && (
              <ACAFileEditUpload
                username={username}
                userUniqueNo={this.props?.uniqueno}
                parentData={acaTableRow}
                handleCloseACAUpload={this.handleCloseACAUpload}
                showACAUpload={showACAUpload}
              />
            )}
            <div className="heading">ACA Tracking</div>
            <Button
              className="btn-ghost"
              type="button"
              onClick={this.downloadACACodeFiles}
            >
              ACA Status Codes File
            </Button>
            <div className="labor-aca-tracking-table">
              <BootstrapTable
                keyField="rowNo"
                data={this.state.laborACATracking}
                columns={acacolumns}
                //  expandRow={acaexpandRow}
                //   rowClasses={acarowClasses}
                hover
              />
            </div>
          </div>
        </div>
        {isFormValid &&
          !isLaborSettingNotChanged &&
          (this.props.isEditLaborandPayrollInfo ||
            this.props?.isNewUserAdded) && (
            <div className="fixed-action ml-auto">
              <div className="d-flex align-content-center flex-wrap">
                <div className="mr-auto message">
                  <span>You have unsaved changes</span>
                </div>
                <button
                  disabled={isDataSubmitting}
                  type="button"
                  className="btn btn-primary btn-discard"
                  onClick={this.handleDiscard}
                >
                  Discard
                </button>

                <button
                  disabled={isDataSubmitting}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.submitLaborData()}
                >
                  Save
                </button>
              </div>
            </div>
          )}

        <>
          {showDuplicateSSNModal && duplicateSSNTableData?.length > 0 && (
            <Modal
              className="duplicate-social-security-number-modal"
              show={showDuplicateSSNModal}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => this.hideDuplicateSSNModal()}
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Duplicate Social Security Number Exists{" "}
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={"tooltip-nickname"}>
                        The below user already has this SSN associated with
                        their user account.
                      </Tooltip>
                    }
                  >
                    <span
                      style={{
                        marginLeft: "4px",
                        display: "inline-block",
                      }}
                    >
                      <InfoTooltipRed />{" "}
                    </span>
                  </OverlayTrigger>{" "}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BootstrapTable
                  keyField="rowNo"
                  data={duplicateSSNTableData || []}
                  columns={duplicateSSNTableColumns}
                  rowClasses={rowClasses}
                  hover
                />
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => this.hideDuplicateSSNModal()}>Ok</Button>
              </Modal.Footer>
            </Modal>
          )}
        </>
      </>
    );
  }
}
