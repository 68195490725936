import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect, Router } from "react-router-dom";
import { Header } from "./Header";
import { Breadcrumbs, IBreadcrumbsProps } from "./Breadcrumbs";
import { protectedRoutes } from "../Routes";
import { User } from "../Services/User";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Pendo } from "../ThirdPartyServices/Pendo";
import IdleTimer from "react-idle-timer";
import { IdleTimeOutModal } from "./IdleTimeOutModal";
import moment from "moment";
import { HeaderMenu } from "../Services/HeaderMenu";
import { resolve, reject } from "q";
import { Slide, ToastContainer, toast } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { API_ROOT } from "../Services/Api-config";
import { GeneralHub } from "../SignalR/Hubs/GeneralHub";
import { UserManagementHub } from "../SignalR/Hubs/UserManagementHub";
import EventEmitter from "reactjs-eventemitter";
import { HubEndPointNames } from "../SignalR/Constants/HubEndPointNames";
import { Utils } from "../Utilis";
import { Actions } from "../SignalR/Actions/Actions";

const DOMPurify = createDOMPurify(window);

export const ProtectedLayout: React.FC = (props: any) => {
  const idleTimer: any = useRef(null);
  let resetTimerControl: any = {};
  let resetTimerControlRefreshToken: any = {};
  const defaultIdleTime = (1 / 2) * 60000;
  const [showModal, setShowModal] = React.useState(false);
  const [userLoggedIn, setUserLoggedIn] = React.useState(false);
  const [isTimedOut, setIsTimedOut] = React.useState(false);
  const [idleTime, setIdleTime] = React.useState(0);

  const [isDownTimepopupshow, setIsDownTimepopupshow] = React.useState(false);
  const [maintainenceTime, setMaintainenceTime] = React.useState("");
  const [serverCurrentTime, setServerCurrentTime] = React.useState("");
  const [timer, setIimer] = React.useState("");
  const [releaseProtocolBannerMsg, setReleaseProtocolBannerMsg] =
    React.useState("");
  const [timerIndex, setTimerIndex] = React.useState(0);
  const [isFiveMinutesBanner, setIsFiveMinutesBanner] = React.useState(false);
  const [isReleaseBannerClosedByUser, setIsReleaseBannerClosedByUser] =
    React.useState(false);
  const [isLogOutFlag, setIsLogOutFlag] = React.useState(0);
  const [intervalId, setIntervalId] = React.useState<any>(-1);
  const [intervalId2, setIntervalId2] = React.useState<any>(-1);
  const [hourMessage, setHourMessage] = React.useState("");
  const [minMessage, setMinMessage] = React.useState("");
  const [loginTime, setLoginTime] = React.useState<any>(new Date());
  const [hubConnection, setHubConnection] = React.useState<any>();
  const [showMaintenanceLogOutModal, setShowMaintenanceLogOutModal] =
    React.useState(false);
  const myStateRef = React.useRef(intervalId);
  const setMyState = (data) => {
    myStateRef.current = data;
    setIntervalId(data);
  };

  React.useEffect(() => {
    // const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
    // for (let i = 1; i < interval_id; i++) {
    //   //window.clearInterval(i);
    // }
    // let isReleaseBannerClosedByUser = localStorage.getItem("isReleaseBannerClosedByUser") === null ? false
    // : localStorage.getItem("isReleaseBannerClosedByUser") === "true" ? true : false;
    // setIsReleaseBannerClosedByUser(isReleaseBannerClosedByUser);
    getInnflowMaintenanceBanner();

    // hubs
    GeneralHub.connectAndOperateHub();
    UserManagementHub.connectAndOperateHub();

    // let id = window.setInterval(() => {

    // }, 60000);
    // setIntervalId2(id);
    // Adding Pendo Snippet after successful first time initilazation
    Pendo.IntegratePendo("layoutMounting");

    const visibilityChange = () => {
      let intervalId = myStateRef.current;
      if (document.hidden) {
        window.clearInterval(intervalId);
      } else {
        if (intervalId !== undefined && intervalId !== -1) {
          window.clearInterval(intervalId);
          setTimerIndex(0);
          setMaintainenceTime("");
          setServerCurrentTime("");
          getInnflowMaintenanceBanner();
        }
      }
    };

    document.addEventListener("visibilitychange", visibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", visibilityChange);
      window.clearInterval(intervalId);
      window.clearInterval(intervalId2);
      const interval_id = window.setInterval(function () {},
      Number.MAX_SAFE_INTEGER);
      for (let i = 1; i < interval_id; i++) {
        window.clearInterval(i);
      }
    };
  }, []);

  React.useEffect(() => {
    if (Number(localStorage.getItem("logoutPopupTime")) > 0 && idleTimer) {
      setIdleTime(Number(localStorage.getItem("logoutPopupTime")) * 60000);
      idleTimer?.current?.start();
    }
  }, [idleTime]);

  React.useEffect(() => {
    recieveNotifyUsersAboutSiteDownData();
  }, []);

  React.useEffect(() => {
    //
    if (maintainenceTime !== "" && serverCurrentTime !== "") {
      // bannerTimeValidation();
      window.clearInterval(intervalId);
      let intervalId1: Number = window.setInterval(() => {
        setTimerIndex((timerIndex) => timerIndex + 1);
      }, 1000);
      setMyState(intervalId1);
    }
  }, [maintainenceTime, serverCurrentTime]);

  // React.useEffect(() => {
  //   //
  //   if (
  //     timerIndex !== 0 &&
  //     maintainenceTime !== "" &&
  //     serverCurrentTime !== ""
  //   ) {
  //     bannerTimeValidation();
  //   }
  // }, [timerIndex]);

  /* Function added for Production Release Protocol */

  const recieveNotifyUsersAboutSiteDownData = () => {
    EventEmitter.subscribe(
      Actions.GeneralHubActions.SITE_DOWN_NOTIFICATION,
      (response) => {
        const data = response?.data;
        if (data) {
          setMaintenanceBannerData(data);
        }
      }
    );
  };

  const setMaintenanceBannerData = (result, isFromApiCall = false) => {
    if (result !== null && result?.length > 0) {
      //
      if (result[0].logOutFlag === 1) {
        setShowMaintenanceLogOutModal(true);
        setTimeout(() => {
          setShowMaintenanceLogOutModal(false);
          logout();
        }, 10000);
      } else if (result[0].minFlag === 1) {
        setLoginTime(new Date());
        setHourMessage(result[0].hourMessage);
        setMinMessage(result[0].minMessage);
        setMaintainenceTime("");
        setServerCurrentTime("");
        setMaintainenceTime(result[0].startDateTime);
        setServerCurrentTime(result[0].currentDateTime);
        // setMaintainenceTime("2023-08-28T10:16:03.94");
        // setServerCurrentTime("2023-08-19T10:15:00.93");
        setIsLogOutFlag(result[0].logOutFlag);
      } else if (result[0].hoursFlag === 1) {
        setLoginTime(new Date());
        setHourMessage(result[0].hourMessage);
        setMinMessage(result[0].minMessage);
        setMaintainenceTime("");
        setServerCurrentTime("");
        setIsLogOutFlag(result[0].logOutFlag);
        setIimer("");
        setReleaseProtocolBannerMsg(result[0].hourMessage);
        setIsFiveMinutesBanner(false);
        setIsDownTimepopupshow(true);
      } else {
        setLoginTime(new Date());
        setHourMessage(result[0].hourMessage);
        setMinMessage(result[0].minMessage);
        setMaintainenceTime("");
        setServerCurrentTime("");
        setIsLogOutFlag(result[0].logOutFlag);
        setIsDownTimepopupshow(false);
        setIimer("");
        setReleaseProtocolBannerMsg("");
        setIsFiveMinutesBanner(false);
      }
    } else {
      setTimerIndex(0);
      setLoginTime(new Date());
      setHourMessage("");
      setMinMessage("");
      setMaintainenceTime("");
      setServerCurrentTime("");
      setIsLogOutFlag(0);
      setIsDownTimepopupshow(false);
      setIimer("");
      setReleaseProtocolBannerMsg("");
      setIsFiveMinutesBanner(false);
    }
    displayBanner(result, isFromApiCall);
  };

  const getInnflowMaintenanceBanner = () => {
    HeaderMenu.InnflowMaintenanceBanner()
      .then(async (result: any | null) => {
        setMaintenanceBannerData(result, true);
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
      });
  };

  const bannerTimeValidation = () => {
    //
    if (isLogOutFlag === 1) {
      setTimeout(() => {
        setShowMaintenanceLogOutModal(false);
        logout();
      }, 100000);
    } else {
      let total =
        Date.parse(maintainenceTime) -
        (Date.parse(serverCurrentTime) + timerIndex * 1000);
      let seconds = Math.floor((total / 1000) % 60);
      let minutes = Math.floor((total / 1000 / 60) % 60);
      let hours = Math.floor((total / 1000 / 60 / 60) % 24);
      /* Condition to check if Downtime is in less than 24 hours or less than 5 mins */
      let days = total / 1000 / 60 / 60 / 24;
      if (days >= 0 && days < 1) {
        if (
          total / 1000 / 60 > 0 &&
          total / 1000 / 60 <= 5 &&
          total / 1000 >= 1
        ) {
          let timer =
            (minutes > 9 ? minutes : "0" + minutes) +
            ":" +
            (seconds > 9 ? seconds : "0" + seconds);

          setIimer(timer);
          setReleaseProtocolBannerMsg(minMessage);
          setIsFiveMinutesBanner(true);
          setIsReleaseBannerClosedByUser(false);
          setIsDownTimepopupshow(false);
          setIsDownTimepopupshow(true);
        } else if (total / 1000 < 1) {
          setIsDownTimepopupshow(false);
          setIimer("");
          setReleaseProtocolBannerMsg("");
          setIsFiveMinutesBanner(false);
          logout();
        } else {
          setIimer(
            (hours > 9 ? hours : "0" + hours) +
              ":" +
              (minutes > 9 ? minutes : "0" + minutes) +
              ":" +
              (seconds > 9 ? seconds : "0" + seconds)
          );
          setReleaseProtocolBannerMsg(hourMessage);
          setIsFiveMinutesBanner(false);
          setIsDownTimepopupshow(false);
          setIsDownTimepopupshow(true);
        }
      } else {
        setIsDownTimepopupshow(false);
        setIimer("");
        setReleaseProtocolBannerMsg("");
        setIsFiveMinutesBanner(false);
      }
    }
  };

  const displayBanner = (result, isFromApiCall = false) => {
    //
    if (result?.length > 0) {
      if (result[0]?.isLogOutFlag === 1) {
        setShowMaintenanceLogOutModal(true);
        setTimeout(() => {
          setShowMaintenanceLogOutModal(false);
          logout();
        }, 10000);
      } else if (result[0]?.minFlag === 1) {
        setReleaseProtocolBannerMsg(result[0]?.minMessage);
        setIsFiveMinutesBanner(true);
        setIsReleaseBannerClosedByUser(false);
        setIsDownTimepopupshow(false);
        setIsDownTimepopupshow(true);
      } else if (result[0]?.hoursFlag === 1 && isFromApiCall) {
        setReleaseProtocolBannerMsg(result[0]?.hourMessage);
        setIsFiveMinutesBanner(false);
        setIsDownTimepopupshow(true);
      } else if (isFromApiCall) {
        setIsDownTimepopupshow(false);
        setIimer("");
        setReleaseProtocolBannerMsg("");
        setIsFiveMinutesBanner(false);
      }
    } else {
      setIsDownTimepopupshow(false);
      setIimer("");
      setReleaseProtocolBannerMsg("");
      setIsFiveMinutesBanner(false);
    }
  };

  const closeReleaseBanner = () => {
    setIsReleaseBannerClosedByUser(true);
    localStorage.setItem("isReleaseBannerClosedByUser", "true");
  };

  const logout = () => {
    User.removeToken()
      .then(async (result: any | null) => {
        if (result) {
          User.isAuthenticated = false;
          User.apiLoginResponse = null;
          localStorage.clear();
          sessionStorage.clear();
          // Adding Pendo Snippet after successful logout
          Pendo.IntegratePendo("userLogout");
          let mergeURL = result.result.mergeURL;
          window.open(
            mergeURL + "?logInUser=" + btoa("logout") + "&logInType=3"
          );
          window.location.replace("/login");
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });

    localStorage.setItem("token", "");
    localStorage.setItem("useDefaultTheme", "");
  };

  const rolePermssionState: any = useSelector(
    (state: any) => state.RolePermissionReducer
  );

  let deepLinkingURL = window.location.pathname;
  let deepLinkingParam = window.location.search;
  localStorage.setItem("deepURL", deepLinkingURL);
  localStorage.setItem("deepParam", deepLinkingParam);

  if (!User.isAuthenticated) {
    return <Redirect to="/login" />;
    // isTokenActive();
  }

  const checkRolePermission = (componentDetails: any) => {
    // if (componentDetails.name === "Schedule") {
    //   return rolePermssionState.permissionViewSchedule;
    // } else

    if (componentDetails.name === "Tip Entry") {
      return rolePermssionState.permissionViewLaborTips;
    } else if (componentDetails.name === "Forecast") {
      return rolePermssionState.permissionViewForecast;
    } else if (componentDetails.name === "SmileID") {
      return rolePermssionState.permissionLaborSmileID;
    } else if (componentDetails.name === "EnterpriseWiseLaborSettings") {
      return rolePermssionState.permissionSettingEnterPriseWise;
    } else if (componentDetails.name === "PayrollProcessor") {
      return rolePermssionState.permissionPayrollProcessor;
    } else if (componentDetails.name === "Reports") {
      return rolePermssionState.permissionMyReports;
    } else if (componentDetails.name === "timeoffrequest") {
      return rolePermssionState.permissionTimeOffRequest;
    } else if (componentDetails.name === "Time Off") {
      return rolePermssionState.permissionPtoSetting;
    } else if (componentDetails.name === "Timesheet") {
      return rolePermssionState.permissionTimesheet;
    } else if (componentDetails.name === "Performance") {
      return rolePermssionState.permissionPerformance;
    } else if (componentDetails.name === "RolePermission") {
      return rolePermssionState.permissionRole;
    } else if (componentDetails.name === "TimeClock IpSetting") {
      return rolePermssionState.permissionTimeClock;
    } else if (componentDetails.name === "Holidays") {
      return rolePermssionState.permissionHoliday;
    } else if (componentDetails.name === "LabordDashboard") {
      return rolePermssionState.permissionLaborDashboard;
    } else if (componentDetails.name === "UnApprove") {
      return rolePermssionState.permissionApprove;
    } else if (componentDetails.name === "Housekeeping") {
      return rolePermssionState.permissionHousekeeping;
    } else if (componentDetails.name === "Users") {
      return rolePermssionState.permissionUserManagement;
    } else if (componentDetails.name === "InvoiceInbox") {
      return rolePermssionState.permissionInvoiceInbox;
    } else if (componentDetails.name === "Entity") {
      return rolePermssionState.permissionEntityManagement;
    } else if (componentDetails.name === "Journal") {
      return rolePermssionState.permissionJournalEntry;
    } else if (componentDetails.name === "payments") {
      return rolePermssionState.permissionMakePayments;
    } else if (componentDetails.name === "payrollImport") {
      return rolePermssionState.permissionPayrollImport;
    } else if (componentDetails.name === "vendors") {
      return rolePermssionState.permissionVendor;
    } else if (componentDetails.name === "Users") {
      return rolePermssionState.permissionUserManagement;
    } else if (componentDetails.name === "invoiceapprovals") {
      return rolePermssionState.permissionInvAppSetting;
    } else if (componentDetails.name === "invoiceapproval") {
      return rolePermssionState.permissionInvApp;
    } else if (componentDetails.name === "BankSync") {
      //return rolePermssionState.permissionBankSync;
      return true;
    } else if (componentDetails.name === "ImportUser") {
      return rolePermssionState.permissionImportUser;
    } else if (componentDetails.name === "epay Onboarding") {
      return rolePermssionState.permissionePayOnboarding;
    } else if (componentDetails.name === "Advanced Accounting") {
      return rolePermssionState.permissionAdvancedAccounting;
    } else if (componentDetails.name === "SalesAccounts") {
      return rolePermssionState.permissionSalesAccounts;
    } else if (componentDetails.name === "DailyTaskCreation") {
      return rolePermssionState.permissionDailyTasks;
    } else if (componentDetails.name === "Assets") {
      return rolePermssionState.permissionAssets;
    } else if (
      componentDetails.name === "PreventativeMaintenance" ||
      componentDetails.name === "schedule"
    ) {
      return rolePermssionState.permissionPreventativeMaintenance;
    } else if (componentDetails.name === "Procedures") {
      return rolePermssionState.permissionProcedures;
    } else if (componentDetails.name === "workOrderDetails") {
      return rolePermssionState.permissionWorkOrders;
    } else if (componentDetails.name === "CommunicationsInbox") {
      return rolePermssionState.permissioncommInbox;
    }

    // else if (componentDetails.name === "alertPreferences" || componentDetails.name === "AlertPreferences") {
    //   return rolePermssionState.permissionAlertPreferences;
    // }
    else {
      return true;
    }
  };
  const refreshToken = () => {
    User.RefreshToken().then((response: any) => {
      User.SetAuthToken(
        response?.result?.token,
        response?.result?.expirationTime,
        response?.result?.logoutPopupTime
      );
    });
  };

  const getRefreshTokenTimeOut = (): number => {
    const expirationTime = localStorage?.getItem("expirationTime") || "";
    const duration: any = moment.duration(
      moment(expirationTime)?.diff(moment())
    );
    const seconds = duration._data.minutes * 60 + duration._data.seconds;
    return seconds || 0;
  };

  const onAction = (e) => {
    const expirationTime = localStorage?.getItem("expirationTime") || "";
    const duration: any = moment.duration(
      moment(expirationTime)?.diff(moment())
    );
    const seconds = duration._data.minutes * 60 + duration._data.seconds;
    if (seconds <= 120 && seconds > 0 && !showModal) {
      refreshToken();
    } else {
      resetTimer();
    }
    if (isTimedOut) {
      setIsTimedOut(false);
    }
  };

  const resetTimer = () => {
    if (resetTimerControl) {
      clearTimeout(resetTimerControl);
    }

    resetTimerControl = setTimeout(() => {
      if (idleTimer?.current) {
        if (typeof idleTimer?.current["reset"] === "function") {
          idleTimer.current.reset();
          if (resetTimerControlRefreshToken) {
            clearTimeout(resetTimerControlRefreshToken);
          }

          resetTimerControlRefreshToken = setTimeout(() => {
            if (
              !showModal &&
              Number(localStorage.getItem("logoutPopupTime")) * 60 >
                getRefreshTokenTimeOut() - 120 &&
              getRefreshTokenTimeOut() - 120 < 180
            ) {
              refreshToken();
            }
          }, (getRefreshTokenTimeOut() - 120) * 1000);

          setIdleTime(defaultIdleTime);
          setIsTimedOut(false);
        }
      }
    }, 10000);
  };

  const onActive = (e) => {
    const expirationTime = localStorage?.getItem("expirationTime") || "";
    const duration: any = moment.duration(
      moment(expirationTime)?.diff(moment())
    );
    const seconds = duration._data.minutes * 60 + duration._data.seconds;
    if (seconds <= 120 && seconds > 0 && !showModal) {
      refreshToken();
    } else {
      resetTimer();
    }
    if (isTimedOut) {
      setIsTimedOut(false);
    }
  };

  const onIdle = (e) => {
    setShowModal(true);
    setIsTimedOut(true);
  };

  const handleClose = () => {
    if (localStorage.getItem("closeIdlePopUp")) {
      localStorage.removeItem("closeIdlePopUp");
    }
    if (showModal) {
      setShowModal(false);
      if (!document.hidden) {
        broadCastMessage("closeIdlePopUp");
      }

      resetTimer();
    }
  };

  const handleLogout = (isAutoLogOut = false) => {
    setShowModal(false);
    if (localStorage.getItem("logOutFromIdlePopUp")) {
      localStorage.removeItem("logOutFromIdlePopUp");
    }
    if (isAutoLogOut) {
      broadCastMessage("logOutFromIdlePopUp");
    }
    props.history.push("/login");

    User.RemoveAuthToken();
  };

  const broadCastMessage = (key: string) => {
    localStorage.setItem(key, "true");
  };

  return (
    <React.Suspense
      fallback={
        <div className="pageLoader">
          <Spinner animation="border" />
        </div>
      }
    >
      <React.Fragment>
        <div className="container-flex-100">
          {/* <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}

          {/* upcoming downtime Banner */}
          {isDownTimepopupshow && !isReleaseBannerClosedByUser && (
            <div className="downtime-modal">
              <div className="pos-fxd">
                <div className="d-flex">
                  <div className="mr-auto msg">
                    <EllipsisWithTooltip placement="bottom">
                      <span
                        className="timer"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(releaseProtocolBannerMsg),
                        }}
                      ></span>
                    </EllipsisWithTooltip>
                  </div>
                  {!isFiveMinutesBanner && (
                    <div className="cross" onClick={closeReleaseBanner}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.4933 5.28662L9.95751 8.82162L6.4225 5.28662L5.24417 6.46495L8.77917 9.99995L5.24417 13.535L6.4225 14.7133L9.95751 11.1783L13.4933 14.7133L14.6717 13.535L11.1367 9.99995L14.6717 6.46495L13.4933 5.28662Z"
                          fill="#0e9074"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div
            className={
              isDownTimepopupshow && !isReleaseBannerClosedByUser
                ? "d-flex downtime-banner"
                : "d-flex"
            }
          >
            <Header />

            <IdleTimer
              crossTab={{
                emitOnAllTabs: true,
              }}
              ref={idleTimer}
              element={document}
              onActive={onActive}
              onIdle={onIdle}
              onAction={onAction}
              debounce={250}
              timeout={idleTime}
              startManually={true}
            />

            <Switch>
              {protectedRoutes.map(({ path, Component }, key) => (
                <Route
                  exact
                  path={path}
                  key={key}
                  render={(props: any) => {
                    if (localStorage.getItem("token") != null) {
                      let isPermitted = checkRolePermission(
                        protectedRoutes[key]
                      );
                      if (isPermitted) {
                        return (
                          <div className="flex-grow-1 overflow-hidden">
                            <Component {...props} />
                          </div>
                        );
                      } else {
                        return <Redirect to="/unauthorize-access" />;
                      }
                    } else {
                      return <Redirect to="/login" />;
                    }
                  }}
                />
              ))}
              <Route
                render={() => {
                  return (
                    <div>
                      <Breadcrumbs crumbs={{} as IBreadcrumbsProps[]} />
                    </div>
                  );
                }}
              />
            </Switch>
            <IdleTimeOutModal
              showModal={showModal}
              handleClose={handleClose}
              handleLogout={handleLogout}
            />

            <Modal
              backdropClassName="idle-bckdrp"
              className="idle-timeout-modal timeout-modal"
              backdrop={"static"}
              keyboard={false}
              show={showMaintenanceLogOutModal}
              centered
            >
              <Modal.Body>
                <div className="description py-lg-4 text-center">
                  <h5>The downtime has started.</h5>
                  <h6>You are being logged out now.</h6>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </React.Fragment>
    </React.Suspense>
  );
};
